export const common_constants_email = "email";
export const common_constants_password = "password";
export const common_constants_token = "Token";
export const common_constants_clinic_id = "clinic_id";
export const common_constants_loginSuccess = "Login Successful";
export const common_constants_name = "name";

export const _DASHBOARD_key = "DASHBOARD";
export const _APPOINTMENTS_key = "APPOINTMENTS";
export const _PATIENTS_key = "PATIENTS";
export const _STAFF_key = "STAFFS";
export const _CLAIMS_key = "CLAIMS";
export const _CLINIC_MANAGEMENT_key = "CLINIC MANAGEMENT";
export const _PAYMENTS_key = "PAYMENTS";
export const _SETTINGS_key = "SETTINGS";

export const _doctors_tab = "Doctors";
export const _directory_tab = "Directory";

// const sampleBreakPoints = {
//   xs: "480px",
//   sm: "576px",
//   md: "768px",
//   lg: "992px",
//   xl: "1200px",
//   xxl: "1600px",
// };

export const data = [
  {
    key: "1",
    pid: "1139",
    name: "Willie Jennie",
    age: "24",
    gender: "Female",
    contact: "+1 802 331-5882",
    address: "116 Hare Street NY WA USA 10001",
    createdDate: "05 Jun 2021 11:00 AM",
    lastVisit: "05 Jun 2021 Tooth Scaling",
    dentalHistory: "Dental Pain/Discomfort",
    email: "willie.jennie@example.com",
  },
  {
    key: "2",
    pid: "1134",
    name: "Bill Sanders",
    age: "29",
    gender: "Male",
    contact: "+1 802 329-5116",
    address: "36 Creek Row NY WA USA 11010",
    createdDate: "02 Jun 2021 12:00 AM",
    lastVisit: "02 Jun 2021 Root Canal",
    dentalHistory: "Dental Pain/Discomfort",
    email: "bill.sanders@example.com",
  },
  {
    key: "3",
    pid: "1140",
    name: "Jane Doe",
    age: "34",
    gender: "Female",
    contact: "+1 802 331-1234",
    address: "45 Maple Street NY WA USA 10003",
    createdDate: "15 Jan 2024 10:00 AM",
    lastVisit: "15 Jan 2024 Filling",
    dentalHistory: "Cavity",
    email: "jane.doe@example.com",
  },
  {
    key: "4",
    pid: "1141",
    name: "John Smith",
    age: "42",
    gender: "Male",
    contact: "+1 802 329-9876",
    address: "89 Birch Lane NY WA USA 11011",
    createdDate: "20 Feb 2024 09:30 AM",
    lastVisit: "20 Feb 2024 Tooth Extraction",
    dentalHistory: "Wisdom Tooth Pain",
    email: "john.smith@example.com",
  },
  {
    key: "5",
    pid: "1142",
    name: "Emily Davis",
    age: "27",
    gender: "Female",
    contact: "+1 802 331-7654",
    address: "23 Elm Drive NY WA USA 10004",
    createdDate: "01 Mar 2024 08:45 AM",
    lastVisit: "01 Mar 2024 Teeth Whitening",
    dentalHistory: "Teeth Staining",
    email: "emily.davis@example.com",
  },
  {
    key: "6",
    pid: "1143",
    name: "Michael Brown",
    age: "31",
    gender: "Male",
    contact: "+1 802 329-4567",
    address: "78 Pine Street NY WA USA 11012",
    createdDate: "12 Apr 2024 01:15 PM",
    lastVisit: "12 Apr 2024 Crown Placement",
    dentalHistory: "Broken Tooth",
    email: "michael.brown@example.com",
  },
  {
    key: "7",
    pid: "1144",
    name: "Olivia Wilson",
    age: "22",
    gender: "Female",
    contact: "+1 802 331-9876",
    address: "90 Cedar Road NY WA USA 10005",
    createdDate: "18 May 2024 03:45 PM",
    lastVisit: "18 May 2024 Dental Cleaning",
    dentalHistory: "Plaque Buildup",
    email: "olivia.wilson@example.com",
  },
  {
    key: "8",
    pid: "1145",
    name: "Lucas Miller",
    age: "36",
    gender: "Male",
    contact: "+1 802 329-8765",
    address: "102 Oak Lane NY WA USA 11013",
    createdDate: "25 Jun 2024 11:00 AM",
    lastVisit: "25 Jun 2024 Gum Treatment",
    dentalHistory: "Gum Swelling",
    email: "lucas.miller@example.com",
  },
  {
    key: "9",
    pid: "1146",
    name: "Charlotte Taylor",
    age: "29",
    gender: "Female",
    contact: "+1 802 331-6789",
    address: "11 Willow Court NY WA USA 10006",
    createdDate: "02 Jul 2024 02:00 PM",
    lastVisit: "02 Jul 2024 X-ray",
    dentalHistory: "Jaw Pain",
    email: "charlotte.taylor@example.com",
  },
  {
    key: "10",
    pid: "1147",
    name: "Ethan Martinez",
    age: "40",
    gender: "Male",
    contact: "+1 802 329-5432",
    address: "34 Aspen Blvd NY WA USA 11014",
    createdDate: "10 Aug 2024 09:15 AM",
    lastVisit: "10 Aug 2024 Braces Adjustment",
    dentalHistory: "Orthodontic Treatment",
    email: "ethan.martinez@example.com",
  },
  {
    key: "11",
    pid: "1148",
    name: "Sophia Anderson",
    age: "33",
    gender: "Female",
    contact: "+1 802 331-4321",
    address: "67 Spruce Street NY WA USA 10007",
    createdDate: "15 Sep 2024 01:30 PM",
    lastVisit: "15 Sep 2024 Root Canal",
    dentalHistory: "Severe Toothache",
    email: "sophia.anderson@example.com",
  },
  {
    key: "12",
    pid: "1149",
    name: "Mason Harris",
    age: "45",
    gender: "Male",
    contact: "+1 802 329-3456",
    address: "89 Fir Lane NY WA USA 11015",
    createdDate: "20 Oct 2024 11:45 AM",
    lastVisit: "20 Oct 2024 Veneer Fitting",
    dentalHistory: "Cosmetic Correction",
    email: "mason.harris@example.com",
  },
  {
    key: "13",
    pid: "1150",
    name: "Amelia Thompson",
    age: "28",
    gender: "Female",
    contact: "+1 802 331-2345",
    address: "12 Walnut Ave NY WA USA 10008",
    createdDate: "05 Nov 2024 10:30 AM",
    lastVisit: "05 Nov 2024 Filling Replacement",
    dentalHistory: "Old Filling Issues",
    email: "amelia.thompson@example.com",
  },
  {
    key: "14",
    pid: "1151",
    name: "Henry Lee",
    age: "37",
    gender: "Male",
    contact: "+1 802 329-1234",
    address: "98 Redwood Drive NY WA USA 11016",
    createdDate: "12 Dec 2024 04:15 PM",
    lastVisit: "12 Dec 2024 Denture Repair",
    dentalHistory: "Denture Damage",
    email: "henry.lee@example.com",
  },
  // Add 16 more rows as required...
];

export const _general_tab = "general";
export const _role_tab = "role";
export const _access_control_tab = "access";
