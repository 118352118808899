import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Menu, message, Modal, Spin } from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import addIcon from "../../../../../assets/add.png";
import closeIcon from "../../../../../assets/close.png";
import infoIcon from "../../../../../assets/info.png";
import moreIcon from "../../../../../assets/more_vert.png";
import API_BASE_URL from "../../../../../env";
import NoteViewTooltip from "../../tabs/notes/NoteViewTooltip";
import NotesOffcanvas from "../../tabs/notes/notesOffcanvas/NotesOffcanvas";
import NoteAttachmentTooltip from "./NoteAttachmentTooltip";

const Notes = () => {
    const Token = localStorage.getItem("Token");
    const { staffId } = useParams();
    const gridRef = useRef(null);
    const gridStyle = useMemo(() => ({ height: "calc(100% - 68px)", width: "100%" }), []);
    const [rowData, setRowData] = useState([]);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [note, setNote] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [noteId, setNoteId] = useState();

    useEffect(() => {
        getNotes();
    }, [Token])

    const getNotes = async () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        }

        try {
            const res = await axios.get(`${API_BASE_URL}/api/v1/clinicNotes/get-notes?providerId=${staffId}`, config);            
            setRowData(res.data.data);
        } catch (err) {
            err.response && message.error(err.response?.data.data.errors);
        } finally {
            setLoading(false);
        }
    };

    const handleEditOpen = (id) => {
        const selectedNote = rowData?.find((item) => item.noteId === id);
        setNote(selectedNote);
        setEditShow(true);
    };

    const handleDeleteNote = (id) => {
        setOpenDeleteModal(true);
        setNoteId(id);
    }

    const deleteNote = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: staffId,
                noteId: noteId
            }
        }

        axios
            .delete(`${API_BASE_URL}/api/v1/clinicNotes/delete-notes`, config)
            .then(res => {
                message.success(res?.data.data);
                setOpenDeleteModal(false);
                getNotes();
            })
            .catch(err => {
                message.error(err.response?.data.data.errors || "Failed to delete the note");
                setOpenDeleteModal(false);
            })
    }

    const formatDate = (value) => {
        if (value) {
            const date = new Date(value);

            // Format the date
            const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };

            // Convert to the desired format
            return date.toLocaleString('en-us', options).replace(',', '');
        } else {
            return '-';
        }
    }

    const columnDefs = [
        {
            field: "title",
            headerName: "TITLE",
            cellRenderer: (params) => {
                // const { title, createdDate, lastModified } = params.data;
                return (

                    <div className="row-data-element">
                        <NoteViewTooltip data={params.data} />
                    </div>
                );
            },
            flex: 2,
            minWidth: 230
        },
        {
            field: "attachment",
            headerName: "ATTACHMENT",
            cellRenderer: (params) => {
                return (
                    <div className="row-data-element">
                        <NoteAttachmentTooltip data={params.data} />
                        {/* <div className="attachments">1 {attachmentSizes}</div> */}
                    </div>
                );
            },
            flex: 1,
            minWidth: 90
        },
        {
            field: "createdDate",
            headerName: "CREATED DATE",
            cellRenderer: (params) => {
                const { createdDate } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="created-date">{formatDate(createdDate)}</div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 210
        },
        {
            field: "updatedAt",
            headerName: "LAST MODIFIED",
            cellRenderer: (params) => {
                const { updatedAt } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="created-date">{formatDate(updatedAt)}</div>
                    </div>
                );
            },
            flex: 2,
            minWidth: 210
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { noteId } = params.data;
                return (
                    <div className='row-data-element'>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="edit"
                                        onClick={() => handleEditOpen(noteId)}
                                    >
                                        Edit
                                    </Menu.Item>
                                    <Menu.Item key="delete"
                                        onClick={() => handleDeleteNote(noteId)}>Delete</Menu.Item>

                                </Menu>
                            }
                            trigger={["click"]}
                        >
                            <img src={moreIcon} alt="More Icon" className="more-icon" />
                        </Dropdown>

                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            // flex: 1,
            minWidth: 150
        }
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No notes to display</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    return (
        <div className="notes-container">
            <div className="notes-header">
                <span className="notes-text">Notes</span>
                <div className="add-note" onClick={() => setAddShow(true)}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-note-text">Note</span>
                </div>
            </div>

            {loading && (
                <Spin />
            )}

            {!loading && (
                <div style={gridStyle} className="ag-theme-quartz">
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        onFirstDataRendered={onFirstDataRendered}
                        tooltipShowDelay={0}
                        rowHeight={rowHeight}
                        gridOptions={gridOptions}
                    />
                </div>
            )}

            <NotesOffcanvas show={addShow} setShow={setAddShow} mode={'add'} data={staffId} getNotes={getNotes} />
            <NotesOffcanvas show={editShow} setShow={setEditShow} mode={'edit'} data={note} getNotes={getNotes} />

            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Note
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a note,
                            this will remove all the note data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteNote}>Yes</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Notes;