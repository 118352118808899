import { message, Spin, Tooltip } from "antd";
import axios from "axios";
import downloadIcon from "../../../../../assets/download.png";
import API_BASE_URL from "../../../../../env";
import CellWithOverflowTooltip from "../../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { useState, useEffect } from "react";

const NoteAttachmentTooltip = ({ data }) => {
    const Token = localStorage.getItem("Token");
    const [spinner, setSpinner] = useState(false);
    const [urlFiles, setUrlFiles] = useState([]);
    const [sumOfFileSizes, setSumOfFileSizes] = useState();

    useEffect(() => {
        if (data.noteUrls && data.noteUrls.length > 0) {
            const fileListArray = [];
            for (let i = 0; i < data.noteUrls.length; i++) {
                let fileObj = {
                    attachmentId: data.attachmentIds[i],
                    url: data.noteUrls[i]
                };

                fileListArray.push(fileObj);
            }
            setUrlFiles(fileListArray);

            if (data.fileSizes) {
                const totalBytes = data.fileSizes.reduce((acc, size) => acc + convertToBytes(size), 0);
                getTotalFileSize(totalBytes);
            };
        } else {
            setSumOfFileSizes('0KB');
            setUrlFiles([]);
        }

    }, [data])

    const getFileNameFromUrl = (url) => {
        if (!url) return "No Attachment";
        return url.split('/').pop(); // Get the last part after the "/"
    };

    const convertToBytes = (size) => {
        const regex = /(\d+(\.\d+)?)\s*(KB|MB|GB|TB)/i;
        const match = size.match(regex);

        if (!match) return 0;

        const value = parseFloat(match[1]);
        const unit = match[3].toUpperCase();

        const sizeInBytes = {
            'KB': value * 1024,
            'MB': value * 1024 * 1024,
            'GB': value * 1024 * 1024 * 1024,
            'TB': value * 1024 * 1024 * 1024 * 1024
        };

        return sizeInBytes[unit] || 0;
    };

    const getTotalFileSize = (bytes) => {
        // Format the total size
        const totalSize = formatSize(bytes);

        setSumOfFileSizes(totalSize);
    }

    const formatSize = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, i))} ${sizes[i]}`;
    };

    const downloadAttachment = (item) => {
        setSpinner(true);
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: data.providerId,
                noteId: data.noteId,
                id: item.attachmentId
            },
            responseType: 'blob', // Important for handling file downloads
        };

        axios
            .get(`${API_BASE_URL}/api/v1/clinicNotes/download-notes`, config)
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data])); // Create a blob URL
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', getFileNameFromUrl(item.url)); // Set filename
                document.body.appendChild(link);
                link.click(); // Trigger the download
                link.remove(); // Clean up
                setSpinner(false);
                message.success("File downloaded successfully");
            })
            .catch(err => {
                const errorMessage = err.response?.data?.errors || "Failed to fetch the file";
                message.error(errorMessage);
                setSpinner(false);
            });
    };


    return (

        <Tooltip
            title={
                <Spin spinning={spinner} tip={'Downloading...'}>
                    {urlFiles && urlFiles.map((item) => (
                        <div className="tooltip-container">
                            <div className="data-container">

                                <div className="notes-data-row">
                                    <span className="attachment-link"><CellWithOverflowTooltip value={getFileNameFromUrl(item.url)} /></span>
                                    <img
                                        src={downloadIcon}
                                        alt="Download"
                                        className="download-icon"
                                        onClick={() => downloadAttachment(item)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                    )}
                </Spin>
            }
            color="white"
            overlayInnerStyle={{
                color: "#000",
                padding: "10px",
                borderRadius: "8px",
            }}
            placement="bottom"
            trigger="click"
        >
            <div
                className="attachments"
            >
                <span>{data.noteUrls.length} </span>
                ({sumOfFileSizes})
            </div>
        </Tooltip>

    )
}

export default NoteAttachmentTooltip;