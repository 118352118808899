import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import delete_alt from "../../../../assets/delete.png";
import edit_alt from "../../../../assets/edit.png";
import API_BASE_URL from "../../../../env";
import { routes_patient_details } from "../../../../utils/RoutingConts";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";

const InsuranceDetails = ({ patientData }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const { patientId } = useParams();
  const [patientDetails, setPatientDetails] = useState([]);
  const [editRecord, setEditRecord] = useState(null);

  const deleteRecord = async (recordId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };

      await axios.delete(`${API_BASE_URL}/api/v1/insurance/deleteInsurance`, {
        ...config,
        params: { recordId, patientId },
      });
      getInsuranceDetailsByPatientId();
      message.success("Record deleted successfully.");
    } catch (error) {
      message.error("Error deleting record:", error);
    }
  };

  const columns = [
    {
      headerName: "member ID",
      field: "member_id",
      cellRenderer: (params) => {
        return (
          <span className="record-id">
            <Link
              to={`${routes_patient_details}/${patientId}/insurance-details/${params.value}`}
            >
              {params.value}
            </Link>
          </span>
        );
      },
    },
    {
      headerName: "insurance provider",
      field: "insurance_company_name",
      cellRenderer: (params) => {
        const { insurance_company_name } = params.data;
        return (
          <span>
            <CellWithOverflowTooltip value={insurance_company_name} />
          </span>
        );
      },
    },
    { headerName: "Guarantor Id", field: "guarantor_id" },
    { headerName: "Eligibility type", field: "eligibility_type" },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <Space>
          <Button
            icon={<img src={edit_alt} alt="edit" style={{ width: 24 }} />}
            type="link"
            onClick={() => handleEdit(params.data)}
          />
          <Button
            icon={<img src={delete_alt} alt="edit" style={{ width: 24 }} />}
            type="link"
            danger
            onClick={() => deleteRecord(params.data.record_id)}
          />
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditRecord(record);
    setDrawerVisible(true);
    // form.setFieldsValue({
    //   createdDate: dayjs(record.createdDate).toISOString(),
    //   updatedDate: dayjs(record.updatedDate).toISOString(),
    // });
    console.log(patientData);
    form.setFieldsValue({
      ...record,
      patientId: patientData.patientId,
      patient_name: `${patientData.firstname} ${patientData.lastname}`,
      practiceId: patientData.practiceId,
      birthdate: moment(patientData.birthdate).format("DD-MM-YYYY"),
    });
  };

  const showDrawer = () => {
    setEditRecord(null);
    setDrawerVisible(true);
    form.resetFields();
    console.log(patientData);
    form.setFieldsValue({
      patientId: patientData.patientId,
      patient_name: `${patientData.firstname} ${patientData.lastname}`,
      practiceId: patientData.practiceId,
      birthdate: moment(patientData.birthdate).format("DD-MM-YYYY"),
    });
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    form.resetFields();
    setEditRecord(null);
  };

  const [disbaledButton, setdisbaledButton] = useState(false);

  // const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setdisbaledButton(true);
    const payload = {
      patient_id: patientId,
      guarantor_id: values.guarantor_id,
      first_name: patientData.firstname,
      last_name: patientData.lastname ? patientData.lastname : "",
      birthdate: moment(patientData.birthdate).format("YYYY-MM-DD"),
      practice_id: values.practiceId,
      insurance_company_name: values.insurance_company_name,
      domain: "",
      customer_id: "",
      member_id: values.member_id,
      responsible_patient_name: "",
      responsible_patient_birthdate: moment(patientData.birthdate).format(
        "YYYY-MM-DD"
      ),
      error: "",
      eligibility_type: values.eligibility_type,
      header: "",
      ppo_individual: "",
      ppo_family: "",
      premier_individual: "",
      premier_family: "",
      outofnetwork_individual: "",
      outofnetwork_family: "",
    };

    try {
      if (editRecord) {
        // Update existing record
        await axios.put(
          `${API_BASE_URL}/api/v1/insurance/updateInsuranceDetails?recordId=${editRecord.record_id}&patientId=${patientId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        message.success("Insurance details updated successfully.");
      } else {
        // Add new record
        await axios
          .post(
            `${API_BASE_URL}/api/v1/insurance/addInsuranceDetails`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((res) => {
            message.success("Insurance details added successfully.");
          })
          .catch((error) => {
            message.error("Error adding Insurance details:", error);
          });
      }
      getInsuranceDetailsByPatientId();
      closeDrawer();
      setdisbaledButton(false);
    } catch (error) {
      message.error(
        editRecord
          ? "Error updating Insurance details:"
          : "Error adding Insurance details:",
        error
      );
    }
  };

  const getInsuranceDetailsByPatientId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(
        `${API_BASE_URL}/api/v1/insurance/getAllInsuranceDetailsByMemberId?patientId=${patientId}`,
        config
      )
      .then((res) => {
        setPatientDetails(res.data.data);
      })
      .catch((err) => {
        alert("Error occured: ", err.response.data.data.errors[0]);
        console.log("Error occured: ", err.response.data.data.errors[0]);
      });
  };

  useEffect(() => {
    getInsuranceDetailsByPatientId();
  }, [patientId]);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const gridOptions = {
    suppressHorizontalScroll: true, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No Insurance details to display</span>',
  };

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  return (
    <div className="medical-history-main-div">
      <div className="medical-history-header-div">
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <span className="medical-history-text">Insurance Details</span>
          <Button
            icon={<PlusOutlined style={{ color: "#006FE6" }} />}
            className="Add-Medical-History-btn"
            onClick={showDrawer}
            type="text"
          >
            Insurance
          </Button>
        </Space>
      </div>

      <div className="medical-history-ag">
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            columnDefs={columns}
            rowData={patientDetails}
            domLayout="autoHeight"
            suppressCellSelection={true}
            rowHeight={80}
            gridOptions={gridOptions}
            rowSelection={rowSelection}
            tooltipShowDelay={0}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>

      <Drawer
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={false}
        destroyOnClose
      >
        <Row className="w-100 d-flex jc-end">
          <CloseOutlined style={{ fontSize: 24 }} onClick={closeDrawer} />
        </Row>
        <Row>
          <span className="add-medical-history">
            {editRecord ? "Edit Insurance Details" : "Add Insurance Details"}
          </span>
        </Row>
        <Spin size="large" spinning={disbaledButton}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="h-100"
          >
            <div className="h-100">
              <div
                style={{
                  margin: "30px 0 0 0",
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Patient ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Input disabled className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Name <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patient_name">
                      <Input placeholder="name" disabled className="input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Birth Date <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="birthdate"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a Customer ID",
                        },
                      ]}
                    >
                      <Input disabled className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Practice ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="practiceId">
                      <Input className="input" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Guarantor Id <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="guarantor_id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter treatment Name",
                        },
                      ]}
                    >
                      <Input placeholder="guarantor id" className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Insurance Company Name{" "}
                      <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="insurance_company_name"
                      rules={[
                        { required: true, message: "Please Enter a type" },
                      ]}
                    >
                      <Input
                        placeholder="insurance company name"
                        className="input"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Member Id <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="member_id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter treatment Name",
                        },
                      ]}
                    >
                      <Input placeholder="member id" className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Eligibility Type <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="eligibility_type"
                      rules={[
                        { required: true, message: "Please Enter a type" },
                      ]}
                    >
                      <Input placeholder="enter a type" className="input" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  {/* <Col span={12}>
                  <span className="input-header">
                    Value <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="value"
                    rules={[
                      { required: true, message: "Please select a value" },
                    ]}
                  >
                    <Select
                      placeholder="Select Value"
                      className="input"
                      bordered={false}
                    >
                      <Option value="active">Active</Option>
                      <Option value="inactive">In Active</Option>
                    </Select>
                  </Form.Item>
                </Col> */}
                  {/* <Col span={12}>
                  <span className="input-header">
                    Description <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="description"
                    rules={[
                      { required: true, message: "Please enter a description" },
                    ]}
                  >
                    <Input.TextArea
                      className="input"
                      placeholder="Enter Description"
                    />
                  </Form.Item>
                </Col> */}
                </Row>
              </div>
              <div style={{ margin: "20px 0 0 0" }}>
                <Form.Item>
                  <Space style={{ justifyContent: "end", width: "100%" }}>
                    <Button onClick={closeDrawer}>Cancel</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disbaledButton}
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default InsuranceDetails;
