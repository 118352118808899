import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import delete_alt from "../../../../assets/delete.png";
import edit_alt from "../../../../assets/edit.png";
import API_BASE_URL from "../../../../env";
import { routes_patient_details } from "../../../../utils/RoutingConts";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { Option } from "antd/es/mentions";

const ClaimDetails = ({ patientData }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const { patientId } = useParams();
  const [patientDetails, setPatientDetails] = useState([]);
  const [editRecord, setEditRecord] = useState(null);

  const deleteRecord = async (recordId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };

      await axios.delete(`${API_BASE_URL}/api/v1/insurance/deleteInsurance`, {
        ...config,
        params: { recordId, patientId },
      });
      getInsuranceDetailsByPatientId();
      message.success("Record deleted successfully.");
    } catch (error) {
      message.error("Error deleting record:", error);
    }
  };

  const data = [
    {
      claimId: "113416",
      claimSubject: "Root Canal Treatment",
      provider: "Cigna Dental",
      insuranceCoverage: "Employer covered - Individual Co-pay 20%",
      claimSubmit: "11 Sep 2024 01:30 PM",
      status: "SUBMITTED",
      statusColor: "yellow",
    },
    {
      claimId: "11319",
      claimSubject: "Dental Cleaning",
      provider: "Ameritas",
      insuranceCoverage: "Employer covered - Individual Co-pay 20%",
      claimSubmit: "07 Aug 2024 05:30 PM",
      status: "APPROVED",
      statusColor: "green",
    },
    {
      claimId: "113520",
      claimSubject: "Wisdom Tooth Extraction",
      provider: "Delta Dental",
      insuranceCoverage: "Employer covered - Individual Co-pay 10%",
      claimSubmit: "21 Oct 2024 10:00 AM",
      status: "PENDING",
      statusColor: "blue",
    },
    {
      claimId: "113625",
      claimSubject: "Cavity Filling",
      provider: "Humana Dental",
      insuranceCoverage: "Employer covered - Individual Co-pay 15%",
      claimSubmit: "05 Dec 2024 03:45 PM",
      status: "REJECTED",
      statusColor: "red",
    },
    {
      claimId: "113721",
      claimSubject: "Braces Adjustment",
      provider: "United Concordia",
      insuranceCoverage: "Employer covered - Individual Co-pay 25%",
      claimSubmit: "18 Nov 2024 02:15 PM",
      status: "SUBMITTED",
      statusColor: "yellow",
    },
    {
      claimId: "113819",
      claimSubject: "Dental Check-up",
      provider: "Guardian",
      insuranceCoverage: "Employer covered - Individual Co-pay 5%",
      claimSubmit: "10 Jan 2025 11:00 AM",
      status: "APPROVED",
      statusColor: "green",
    },
    {
      claimId: "113920",
      claimSubject: "Implant Surgery",
      provider: "MetLife Dental",
      insuranceCoverage: "Employer covered - Individual Co-pay 20%",
      claimSubmit: "15 Feb 2025 09:30 AM",
      status: "PENDING",
      statusColor: "blue",
    },
  ];

  const columns = [
    {
      headerName: "claim ID",
      field: "claimId",
      cellRenderer: (params) => {
        return (
          <span className="record-id">
            <Link
              to={`${routes_patient_details}/${patientId}/claim-details/${params.value}`}
            >
              {params.value}
            </Link>
          </span>
        );
      },
    },
    {
      headerName: "claim subject",
      field: "claimSubject",
      cellRenderer: (params) => {
        const { claimSubject } = params.data;
        return (
          <span>
            <CellWithOverflowTooltip value={claimSubject} />
          </span>
        );
      },
    },
    { headerName: "provider", field: "provider" },
    {
      headerName: "insurance coverage",
      field: "insuranceCoverage",
      cellRenderer: (params) => {
        const { insuranceCoverage } = params.data;
        return (
          <span>
            <CellWithOverflowTooltip value={insuranceCoverage} />
          </span>
        );
      },
    },
    { headerName: "claim submit", field: "claimSubmit" },
    {
      headerName: "status",
      field: "status",
      cellRenderer: (params) => {
        const { status } = params.data;
        return (
          <span
            className={
              status === "APPROVED"
                ? "eligibility-check-status-approved"
                : status === "REJECTED"
                ? "eligibility-check-status-rejected"
                : "eligibility-check-status-submitted"
            }
          >
            {status}
          </span>
        );
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <Space>
          <Button
            icon={<img src={edit_alt} alt="edit" style={{ width: 24 }} />}
            type="link"
            onClick={() => handleEdit(params.data)}
          />
          <Button
            icon={<img src={delete_alt} alt="edit" style={{ width: 24 }} />}
            type="link"
            danger
            onClick={() => deleteRecord(params.data.record_id)}
          />
        </Space>
      ),
      pinned: "right", // This fixes the column to the right side
      suppressMovable: true, // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
      minWidth: 88,
    },
  ];

  const handleEdit = (record) => {
    setEditRecord(record);
    setDrawerVisible(true);
    form.setFieldsValue({
      //   ...record,
      //   patientId: patientData.patientId,
      //   patient_name: `${patientData.firstname} ${patientData.lastname}`,
      //   practiceId: patientData.practiceId,
      //   birthdate: moment(patientData.birthdate).format("DD-MM-YYYY"),
    });
  };

  const showDrawer = () => {
    setEditRecord(null);
    setDrawerVisible(true);
    form.resetFields();
    console.log(patientData);
    form.setFieldsValue({
      //   patientId: patientData.patientId,
      //   patient_name: `${patientData.firstname} ${patientData.lastname}`,
      //   practiceId: patientData.practiceId,
      //   birthdate: moment(patientData.birthdate).format("DD-MM-YYYY"),
    });
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    form.resetFields();
    setEditRecord(null);
  };

  const [disbaledButton, setdisbaledButton] = useState(false);

  // const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setdisbaledButton(true);
    const payload = {
      patient_id: patientId,
      guarantor_id: values.guarantor_id,
      first_name: patientData.firstname,
      last_name: patientData.lastname ? patientData.lastname : "",
      birthdate: moment(patientData.birthdate).format("YYYY-MM-DD"),
      practice_id: values.practiceId,
      insurance_company_name: values.insurance_company_name,
      domain: "",
      customer_id: "",
      member_id: values.member_id,
      responsible_patient_name: "",
      responsible_patient_birthdate: moment(patientData.birthdate).format(
        "YYYY-MM-DD"
      ),
      error: "",
      eligibility_type: values.eligibility_type,
      header: "",
      ppo_individual: "",
      ppo_family: "",
      premier_individual: "",
      premier_family: "",
      outofnetwork_individual: "",
      outofnetwork_family: "",
    };

    try {
      if (editRecord) {
        // Update existing record
        await axios.put(
          `${API_BASE_URL}/api/v1/insurance/updateInsuranceDetails?recordId=${editRecord.record_id}&patientId=${patientId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        message.success("Insurance details updated successfully.");
      } else {
        // Add new record
        await axios
          .post(
            `${API_BASE_URL}/api/v1/insurance/addInsuranceDetails`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${Token}`,
              },
            }
          )
          .then((res) => {
            message.success("Insurance details added successfully.");
          })
          .catch((error) => {
            message.error("Error adding Insurance details:", error);
          });
      }
      getInsuranceDetailsByPatientId();
      closeDrawer();
      setdisbaledButton(false);
    } catch (error) {
      message.error(
        editRecord
          ? "Error updating Insurance details:"
          : "Error adding Insurance details:",
        error
      );
    }
  };

  const getInsuranceDetailsByPatientId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(
        `${API_BASE_URL}/api/v1/insurance/getAllInsuranceDetailsByMemberId?patientId=${patientId}`,
        config
      )
      .then((res) => {
        setPatientDetails(res.data.data);
      })
      .catch((err) => {
        alert("Error occured: ", err.response.data.data.errors[0]);
        console.log("Error occured: ", err.response.data.data.errors[0]);
      });
  };

  useEffect(() => {
    getInsuranceDetailsByPatientId();
  }, [patientId]);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const gridOptions = {
    suppressHorizontalScroll: true, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No Claim details to display</span>',
  };

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  return (
    <div className="claim-details-main-div">
      <div className="medical-history-header-div">
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <span className="medical-history-text">Claim Details</span>
          <Button
            icon={<PlusOutlined style={{ color: "#006FE6" }} />}
            className="Add-Medical-History-btn"
            onClick={showDrawer}
            type="text"
          >
            Claim
          </Button>
        </Space>
      </div>

      <div className="medical-history-ag">
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            columnDefs={columns}
            rowData={data}
            rowHeight={80}
            gridOptions={gridOptions}
            rowSelection={rowSelection}
            tooltipShowDelay={0}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>

      <Drawer
        width={420}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={false}
        destroyOnClose
        footer={
          <div style={{ padding: "20px 0" }}>
            <Form.Item>
              <Space style={{ justifyContent: "end", width: "100%" }}>
                <Button onClick={closeDrawer}>Cancel</Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={disbaledButton}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </div>
        }
      >
        <Row className="w-100 d-flex jc-end">
          <CloseOutlined style={{ fontSize: 24 }} onClick={closeDrawer} />
        </Row>
        <Row>
          <span className="add-medical-history">
            {editRecord ? "Edit Claim" : "Add Claim"}
          </span>
        </Row>
        <Spin size="large" spinning={disbaledButton}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="h-100"
          >
            <div className="h-100">
              <div
                style={{
                  margin: "30px 0 0 0",
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Row gutter={[16, 24]}>
                  <Col span={24}>
                    <span className="input-header">
                      Claim ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Record ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Appointment ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Appointment name <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Claim topic <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Provider <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Insurance Coverage{" "}
                      <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <span className="input-header">
                      Co Insurance Pay(%){" "}
                      <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Select
                        placeholder="Select"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default ClaimDetails;
