import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Space,
  Drawer,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import API_BASE_URL from "../../../../env";
import dayjs from "dayjs";
import edit_alt from "../../../../assets/edit.png";
import delete_alt from "../../../../assets/delete.png";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import { routes_patient_details } from "../../../../utils/RoutingConts";

const { Option } = Select;

const MedicalHistory = ({ patientData }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();
  const Token = localStorage.getItem("Token");
  const { patientId } = useParams();
  const [patientDetails, setPatientDetails] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [deletingRowId, setDeletingRowId] = useState(null); // Track which row is being deleted

  const handleDelete = async (record) => {
    setDeletingRowId(record); // Set the deleting row
    await deleteRecord(record);
    setDeletingRowId(null); // Reset after deletion
  };

  const deleteRecord = async (recordId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    await axios
      .delete(`${API_BASE_URL}/api/v1/medicalHistory/deleteMedicalHistory`, {
        ...config,
        params: { recordId, patientId },
      })
      .then((res) => {
        getMedicalHistoryByPatientId();
        message.success("Record deleted successfully.");
      })
      .catch((err) => {
        getMedicalHistoryByPatientId();
        message.error("Error deleting record:", err);
      });
  };

  const columns = [
    {
      headerName: "Record ID",
      field: "recordId",
      cellRenderer: (params) => {
        return (
          <span className="record-id">
            <Link
              to={`${routes_patient_details}/${patientId}/medical-history/${params.value}`}
            >
              {params.value}
            </Link>
          </span>
        );
      },
    },
    {
      headerName: "Description",
      field: "description",
      cellRenderer: (params) => {
        const { description } = params.data;
        return (
          <span className="description">
            <CellWithOverflowTooltip value={description} />
          </span>
        );
      },
    },
    { headerName: "Type", field: "type" },
    {
      headerName: "Name",
      field: "name",
      cellRenderer: (params) => {
        const { name } = params.data;
        return (
          <span>
            <CellWithOverflowTooltip value={name} />
          </span>
        );
      },
    },
    { headerName: "Customer ID", field: "custId" },
    { headerName: "Value", field: "value" },
    {
      headerName: "Created Date",
      field: "createdDate",
      valueFormatter: (params) =>
        dayjs(params.value).format("DD MMM YYYY HH:mm A"),
    },
    {
      headerName: "Updated Date",
      field: "updatedDate",
      valueFormatter: (params) =>
        dayjs(params.value).format("DD MMM YYYY HH:mm A"),
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: (params) => (
        <Space>
          <Button
            icon={<img src={edit_alt} alt="edit" style={{ width: 24 }} />}
            type="link"
            onClick={() => handleEdit(params.data)}
          />
          {deletingRowId === params.data.recordId ? (
            <span>Deleting...</span> // Show only for the row being deleted
          ) : (
            <Button
              icon={<img src={delete_alt} alt="edit" style={{ width: 24 }} />}
              type="link"
              danger
              onClick={() => handleDelete(params.data.recordId)}
            />
          )}
        </Space>
      ),
      pinned: "right", // This fixes the column to the right side
      suppressMovable: true, // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
      minWidth: 88,
    },
  ];

  const handleEdit = (record) => {
    setEditRecord(record);
    setDrawerVisible(true);
    form.setFieldsValue({
      ...record,
      createdDate: dayjs(record.createdDate).toISOString(),
      updatedDate: dayjs(record.updatedDate).toISOString(),
    });
  };

  const showDrawer = () => {
    setEditRecord(null);
    setDrawerVisible(true);
    form.resetFields();
    form.setFieldsValue({
      patientId: patientData.patientId,
      patient_name: `${patientData.firstname} ${patientData.lastname}`,
      practiceId: patientData.practiceId,
    });
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    form.resetFields();
    setEditRecord(null);
  };

  const [disbaledButton, setdisbaledButton] = useState(false);
  const onFinish = async (values) => {
    setdisbaledButton(true);
    const payload = {
      ...values,
      createdDate: dayjs(values.createdDate || new Date()).toISOString(),
      updatedDate: dayjs().toISOString(),
    };

    try {
      if (editRecord) {
        // Update existing record
        await axios.put(
          `${API_BASE_URL}/api/v1/medicalHistory/editMedicalHistory?recordId=${editRecord.recordId}&patientId=${patientId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        message.success("Medical history updated successfully.");
      } else {
        // Add new record
        await axios.post(
          `${API_BASE_URL}/api/v1/medicalHistory/addMedicalHistory`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        message.success("Medical history added successfully.");
      }
      getMedicalHistoryByPatientId();
      closeDrawer();
      setdisbaledButton(false);
    } catch (error) {
      message.error(
        editRecord
          ? "Error updating medical history:"
          : "Error adding medical history:",
        error
      );
    }
  };

  const getMedicalHistoryByPatientId = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .get(
        `${API_BASE_URL}/api/v1/medicalHistory/getMedicalHistoryByPatientId?patientId=${patientId}`,
        config
      )
      .then((res) => {
        setPatientDetails(res.data.data);
        // setdisbaledButton(true);
      })
      .catch((err) => {
        message.error(err.response.data.status_message);
      });
  };

  useEffect(() => {
    getMedicalHistoryByPatientId();
  }, [patientId]);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate:
      '<span class="custom-no-rows">No medical history to display</span>',
  };
  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      // flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  return (
    <div className="medical-history-main-div">
      <div className="medical-history-header-div">
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <span className="medical-history-text">Medical History</span>
          <Button
            icon={<PlusOutlined style={{ color: "#006FE6" }} />}
            className="Add-Medical-History-btn"
            onClick={showDrawer}
            type="text"
          >
            Add Medical History
          </Button>
        </Space>
      </div>

      <div className="medical-history-ag">
        <div style={gridStyle} className="ag-theme-quartz">
          <AgGridReact
            ref={gridRef}
            columnDefs={columns}
            rowData={patientDetails}
            rowHeight={80}
            gridOptions={gridOptions}
            rowSelection={rowSelection}
            tooltipShowDelay={0}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>

      <Drawer
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
        closeIcon={false}
        destroyOnClose
      >
        <Row className="w-100 d-flex jc-end">
          <CloseOutlined style={{ fontSize: 24 }} onClick={closeDrawer} />
        </Row>
        <Row>
          <span className="add-medical-history">
            {editRecord ? "Edit Medical History" : "Add Medical History"}
          </span>
        </Row>
        <Spin size="large" spinning={disbaledButton}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="h-100"
          >
            <div className="h-100">
              <div
                style={{
                  margin: "30px 0 0 0",
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Patient ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patientId">
                      <Input disabled className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Name <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="patient_name">
                      <Input placeholder="name" disabled className="input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Treatment Name <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter treatment Name",
                        },
                      ]}
                    >
                      <Input placeholder="treatment name" className="input" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Treatment Type <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="type"
                      rules={[
                        { required: true, message: "Please Enter a type" },
                      ]}
                    >
                      <Input placeholder="Enter a type" className="input" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Customer ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="custId"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a Customer ID",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Customer ID"
                        className="input"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Practice ID <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item name="practiceId">
                      <Input className="input" disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <span className="input-header">
                      Value <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="value"
                      rules={[
                        { required: true, message: "Please select a value" },
                      ]}
                    >
                      <Select
                        placeholder="Select Value"
                        className="input"
                        bordered={false}
                      >
                        <Option value="active">Active</Option>
                        <Option value="inactive">In Active</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <span className="input-header">
                      Description <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a description",
                        },
                      ]}
                    >
                      <Input.TextArea
                        className="input"
                        placeholder="Enter Description"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div style={{ margin: "20px 0 0 0" }}>
                <Form.Item>
                  <Space style={{ justifyContent: "end", width: "100%" }}>
                    <Button onClick={closeDrawer}>Cancel</Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disbaledButton}
                    >
                      Save
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </div>
  );
};

export default MedicalHistory;
