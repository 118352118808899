import { Row } from 'antd';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../../assets/arrow_back.png";
import { routes_patient_details } from '../../../../utils/RoutingConts';
import '../appointment/AppointmentDetails.css';

const EligibilityCheckDetails = () => {
    const navigate = useNavigate();
    const { patientId, verificationId } = useParams();

    return (
        <div className="appointment-details-container">
            <Helmet>
                <title>Eligibility Check Details</title>
            </Helmet>


            <div className='appointment-details-view'>
                <div className='appointment-details-header'>
                    <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(`${routes_patient_details}/${patientId}`)} />
                    <div className='appointment-info'>
                        <div className='appointment-basic-info'>
                            <Row>
                                <span className='appointment-id'>{verificationId}</span>
                            </Row>
                            <Row className='appointment-desc'>
                                <span className='appointment-title'>Dental Crown Implant</span>
                            </Row>
                            <Row className='appointment-date-time'>
                                <span>09 Sep 2024</span>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EligibilityCheckDetails;