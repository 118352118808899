import { Breadcrumb, Dropdown, Layout, Menu, message } from "antd";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import addIcon from "../../assets/add.png";
import {
  default as calendar,
  default as calendar_icon,
} from "../../assets/calendar.png";
import {
  default as claims,
  default as claims_icon,
} from "../../assets/claims.png";
import {
  default as clinicm,
  default as clinicm_icon,
} from "../../assets/clinicm.png";
import {
  default as dashboard,
  default as dashboard_icon,
} from "../../assets/dashboard.png";
import profile from "../../assets/default_propic.png";
import ivoryLogo from "../../assets/ivorylogo.png";
import notificationIcon from "../../assets/notifications_none.png";
import patients_icon from "../../assets/patients.png";
import patients from "../../assets/patients_icon.png";
import {
  default as payment,
  default as payment_icon,
} from "../../assets/payment.png";
import settings_icon from "../../assets/settings-icon.png";
import settings from "../../assets/settings.png";
import staff_icon from "../../assets/staff.png";
import staff from "../../assets/staff_icon.png";
import API_BASE_URL from "../../env";
import {
  _APPOINTMENTS_key,
  _CLAIMS_key,
  _CLINIC_MANAGEMENT_key,
  _DASHBOARD_key,
  _PATIENTS_key,
  _PAYMENTS_key,
  _SETTINGS_key,
  _STAFF_key,
  common_constants_token,
} from "../../utils/commonConsts";
import {
  _appointment_details,
  _appointments,
  _claims,
  _clinic_management,
  _dashboard,
  _eligibility_details,
  _insurance_patient_details,
  _medical_history_details,
  _patient_claim_details,
  _patient_details,
  _patients,
  _payments,
  _settings,
  _staff_details,
  _staffs,
  routes_appointments,
  routes_claims,
  routes_clinic_Management,
  routes_home,
  routes_patient_details,
  routes_patients,
  routes_payments,
  routes_settings,
  routes_staff_details,
  routes_staffs,
} from "../../utils/RoutingConts";
import Dashboard from "../dashboard/Dashboard";
import PatientDetails from "../patients/patientdetails/PatientDetails";
import Patients from "../patients/Patients";
import AppointmentDetails from "../patients/tabs/appointment/AppointmentDetails";
import EligibilityCheckDetails from "../patients/tabs/eligibility-check/EligibilityCheckDetails";
import InsuranceData from "../patients/tabs/insuance-details/InsuranceData";
import MedicalHistoryData from "../patients/tabs/medical-history/MedicalHistoryData";
import Settings from "../settings/Settings";
import Staff from "../staff/Staff";
import StaffDetails from "../staff/staff-details/StaffDetails";
import "./Home.css";
import NavProfilePicture from "./NavProfilePicture";
import PatientClaimData from "../patients/tabs/claim-details/PatientClaimData";

const { Header, Content, Sider } = Layout;

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState("");
  // const { ProData, handleGetClinicProPic } = UserAuth();
  const clinic_id = localStorage.getItem("clinic_id");

  useEffect(() => {
    if (clinic_id) {
      handleGetClinicProPic();
    }
  }, [clinic_id]);

  const [ProData, setProData] = useState();
  const Token = localStorage.getItem("Token");

  const handleGetClinicProPic = () => {
    if (clinic_id !== null) {
      let config = {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      axios
        .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
        .then((res) => {
          const data = res.data.data;
          setProData(data);
        })
        .catch((err) => {
          message.error(
            err.response.data.data.errors || "Failed to load clinic details"
          );
        });
    }
  };

  const routeKeyMappings = useMemo(
    () => [
      { route: routes_staffs, key: _STAFF_key },
      { route: routes_patients, key: _PATIENTS_key },
      { route: routes_appointments, key: _APPOINTMENTS_key },
      { route: routes_claims, key: _CLAIMS_key },
      { route: routes_clinic_Management, key: _CLINIC_MANAGEMENT_key },
      { route: routes_payments, key: _PAYMENTS_key },
      { route: routes_settings, key: _SETTINGS_key },
    ],
    []
  );

  const logout = () => {
    localStorage.removeItem("Token");
    message.warning("Your session has expired. You have been logged out.");
    // Redirect to login page or handle logout UI
    window.location.href = "/login";
  };

  const setAutoLogout = (token) => {
    try {
      const decodedToken = jwtDecode(token); // Decode the token
      const currentTime = Date.now() / 1000; // Current time in seconds
      const expiresIn = decodedToken.exp - currentTime; // Calculate remaining time

      if (expiresIn > 0) {
        // Set a timeout to log the user out when the token expires
        setTimeout(() => {
          logout();
        }, expiresIn * 1000); // Convert to milliseconds
      } else {
        // Token already expired
        logout();
      }
    } catch (error) {
      console.error("Invalid token", error);
      logout();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (token) {
      setAutoLogout(token);
    }

    const matchingRoute = routeKeyMappings.find(({ route }) =>
      location.pathname.startsWith(route)
    );
    setCurrent(matchingRoute ? matchingRoute.key : _DASHBOARD_key);
  }, [location.pathname, routeKeyMappings]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem(common_constants_token);
    localStorage.removeItem("clinic_id");
    localStorage.removeItem("clinic_name");
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="1" onClick={handleLogout}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  const breadcrumbMap = useMemo(
    () => ({
      [routes_appointments]: _APPOINTMENTS_key,
      [routes_patients]: _PATIENTS_key,
      [routes_staffs]: _STAFF_key,
      [routes_claims]: _CLAIMS_key,
      [routes_clinic_Management]: _CLINIC_MANAGEMENT_key,
      [routes_payments]: _PAYMENTS_key,
      [routes_settings]: _SETTINGS_key,
    }),
    []
  );

  const breadcrumbItems = useMemo(() => {
    const path = location.pathname;

    if (path.startsWith(routes_staff_details)) {
      return [
        { title: <span className="main-breadcrumb">DASHBOARD</span> },
        {
          title: (
            <span
              onClick={() => navigate(routes_staffs)}
              className="main-breadcrumb"
            >
              {_STAFF_key}
            </span>
          ),
          link: routes_staffs,
        }, // Link to customers list
        { title: "DOCTOR DETAILS" }, // Show loading while fetching clinic name
      ];
    }

    if (path.startsWith(routes_patient_details)) {
      const segments = path.split("/").filter(Boolean);
      // console.log(segments[4])
      if (segments.length > 4) {
        return [
          { title: <span className="main-breadcrumb">DASHBOARD</span> },
          {
            title: (
              <span
                onClick={() => navigate(routes_patients)}
                className="main-breadcrumb"
              >
                {_PATIENTS_key}
              </span>
            ),
            link: routes_patients,
          },
          {
            title: (
              <span
                onClick={() =>
                  navigate(`${routes_patient_details}/${segments[3]}`)
                }
                className="main-breadcrumb"
              >
                PATIENTS DETAILS
              </span>
            ),
          },
          {
            title:
              segments[4] === "insurance-details"
                ? "insurance details information"
                : segments[4] === "medical-history"
                ? "Medical history information"
                : segments[4] === "eligibility-check"
                ? "Eligibility Check information"
                : segments[4] === "claim-details"
                ? "claim-details"
                : "Appointment Information",
          },
        ];
      }

      return [
        { title: <span className="main-breadcrumb">DASHBOARD</span> },
        {
          title: (
            <span
              onClick={() => navigate(routes_patients)}
              className="main-breadcrumb"
            >
              {_PATIENTS_key}
            </span>
          ),
          link: routes_patients,
        },
        { title: "PATIENTS DETAILS" },
      ];
    }

    const breadcrumbLabel = breadcrumbMap[path] || "";
    return [
      { title: <span className="main-breadcrumb">DASHBOARD</span> },
      { title: <span className="dynamic-breadcrumb">{breadcrumbLabel}</span> },
    ];
  });

  const onClick = (e) => {
    console.log("click ", e.key);
    setCurrent(e.key);
  };

  // useEffect(() => {
  //   handleGetClinicProPic();
  // }, [clinic_id]);

  return (
    <Layout className="home-layout">
      <Header className="top-navbar">
        <img className="logo" src={ivoryLogo} alt="Ivory Logo" />
        <div className="actions-container">
          <span className="quick-actions">
            <img src={addIcon} /> Quick Actions
          </span>
          <img
            src={notificationIcon}
            alt="Notifications"
            className="notifications"
          />
          <div className="user">
            <div className="user-profile">
              <span className="user-name">
                {ProData?.first_name ? ProData?.first_name : "First name"}{" "}
                {ProData?.last_name ? ProData?.last_name : "Last name"}
              </span>
              <span className="user-role">
                {ProData?.role_names[0] ? ProData?.role_names[0] : "N/A"}
              </span>
            </div>
            <Dropdown overlay={userMenu} trigger={["click"]}>
              {ProData?.profile_picture_url ? (
                <div>
                  <NavProfilePicture
                    profile_picture_url={
                      ProData?.profile_picture_url &&
                      ProData?.profile_picture_url.split(".com/")[1]
                    }
                  />
                </div>
              ) : (
                <img
                  className="profile-pic"
                  src={profile}
                  alt="Profile Picture"
                />
              )}
            </Dropdown>
          </div>
        </div>
      </Header>
      <Layout className="home-content">
        <Sider
          className="side-navbar"
          collapsed={collapsed}
          style={{
            position: "relative",
          }}
          collapsible
          onCollapse={toggleCollapsed}
        >
          <Menu
            defaultSelectedKeys={[
              breadcrumbMap[location.pathname] || "DASHBOARD",
            ]}
            mode="inline"
            inlineCollapsed={collapsed}
            className="side-navbar-menu"
            onClick={onClick}
          >
            <Menu.Item
              disabled
              key={_DASHBOARD_key}
              icon={
                current === _DASHBOARD_key ? (
                  <img src={dashboard} alt="" className="menu-icon" />
                ) : (
                  <img src={dashboard_icon} alt="" className="menu-icon" />
                )
              }
            >
              {/* <Link to={routes_home}>
                {current === _DASHBOARD_key ? (
                  <span className="menu-items-selected">Dashboard</span>
                ) : (
                  <span className="menu-items">Dashboard</span>
                )}
              </Link> */}
              Dashboard
            </Menu.Item>
            <Menu.Item
              className="clinic-item"
              disabled
              icon={<div style={{ display: "none" }}></div>}
            >
              <span className="clinic-text">Clinic</span>
            </Menu.Item>

            <Menu.Item
              disabled
              key={_APPOINTMENTS_key}
              icon={
                current === _APPOINTMENTS_key ? (
                  <img src={calendar} alt="" className="menu-icon" />
                ) : (
                  <img src={calendar_icon} alt="" className="menu-icon" />
                )
              }
            >
              {/* <Link to={routes_appointments}>
                {current === _APPOINTMENTS_key ? (
                  <span className="menu-items-selected">Appointments</span>
                ) : (
                  <span className="menu-items">Appointments</span>
                )}
              </Link> */}
              Appointments
            </Menu.Item>

            <Menu.Item
              key={_PATIENTS_key}
              icon={
                current === _PATIENTS_key ? (
                  <img src={patients} alt="" className="menu-icon" />
                ) : (
                  <img src={patients_icon} alt="" className="menu-icon" />
                )
              }
              className={
                current === _PATIENTS_key ? "menu-items-selected" : "menu-items"
              }
            >
              <Link to={routes_patients}>
                <span>Patients</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key={_STAFF_key}
              icon={
                current === _STAFF_key ? (
                  <img src={staff} alt="" className="menu-icon" />
                ) : (
                  <img src={staff_icon} alt="" className="menu-icon" />
                )
              }
              className={
                current === _STAFF_key ? "menu-items-selected" : "menu-items"
              }
            >
              <Link to={routes_staffs}>
                <span>Staffs</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              disabled
              key={_CLAIMS_key}
              icon={
                current === _CLAIMS_key ? (
                  <img src={claims} alt="" className="menu-icon" />
                ) : (
                  <img src={claims_icon} alt="" className="menu-icon" />
                )
              }
            >
              {/* <Link to={routes_claims}>
                {current === _CLAIMS_key ? (
                  <span className="menu-items-selected">Claims</span>
                ) : (
                  <span className="menu-items">Claims</span>
                )}
              </Link> */}
              Claims
            </Menu.Item>

            <Menu.Item
              disabled
              key={_CLINIC_MANAGEMENT_key}
              icon={
                current === _CLINIC_MANAGEMENT_key ? (
                  <img src={clinicm} alt="" className="menu-icon" />
                ) : (
                  <img src={clinicm_icon} alt="" className="menu-icon" />
                )
              }
            >
              {/* <Link to={routes_clinic_Management}>
                {current === _CLINIC_MANAGEMENT_key ? (
                  <span className="menu-items-selected">Clinic Management</span>
                ) : (
                  <span className="menu-items">Clinic Management</span>
                )}
              </Link> */}
              Clinic Management
            </Menu.Item>

            <Menu.Item
              disabled
              key={_PAYMENTS_key}
              icon={
                current === _PAYMENTS_key ? (
                  <img src={payment} alt="" className="menu-icon" />
                ) : (
                  <img src={payment_icon} alt="" className="menu-icon" />
                )
              }
            >
              {/* <Link to={routes_payments}>
                {current === _PAYMENTS_key ? (
                  <span className="menu-items-selected">Payments</span>
                ) : (
                  <span className="menu-items">Payments</span>
                )}
              </Link> */}
              Payments
            </Menu.Item>

            <Menu.Item
              key={_SETTINGS_key}
              icon={
                current === _SETTINGS_key ? (
                  <img src={settings} alt="" className="menu-icon" />
                ) : (
                  <img src={settings_icon} alt="" className="menu-icon" />
                )
              }
              className={
                current === _SETTINGS_key ? "menu-items-selected" : "menu-items"
              }
            >
              <Link to={routes_settings}>
                <span>Settings</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Content className="content">
            <Breadcrumb className="breadcrumb" items={breadcrumbItems} />
            {/* Dynamic breadcrumb */}
            <Routes>
              <Route path="/" element={<Navigate to={routes_home} />} />
              <Route path={_dashboard} element={<Dashboard />} />
              <Route path={_appointments} element={<Staff />} />
              <Route path={_staffs} element={<Staff />} />
              <Route path={_patients} element={<Patients />} />
              <Route path={_patient_details} element={<PatientDetails />} />
              <Route
                path={_patient_claim_details}
                element={<PatientClaimData />}
              />
              <Route
                path={_insurance_patient_details}
                element={<InsuranceData />}
              />
              <Route
                path={_medical_history_details}
                element={<MedicalHistoryData />}
              />
              <Route
                path={_appointment_details}
                element={<AppointmentDetails />}
              />
              <Route
                path={_eligibility_details}
                element={<EligibilityCheckDetails />}
              />
              <Route path={_claims} element={<Staff />} />
              <Route path={_clinic_management} element={<Staff />} />
              <Route path={_payments} element={<Staff />} />
              <Route path={_settings} element={<Settings />} />
              <Route path={_staff_details} element={<StaffDetails />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Home;
