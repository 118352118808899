import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Form,
  message,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import propic from "../../../../assets/default_propic.png";
import edit_icon from "../../../../assets/edit.png";
import moreIcon from "../../../../assets/expand_more.png";
import { Option } from "antd/es/mentions";
import API_BASE_URL from "../../../../env";
import axios from "axios";
import ClinicImageComponent from "./clinic_propic/ClinicPropic";
import { UserAuth } from "../../../../context_api/ContextApi";

const ClinicProfile = () => {
  const [form] = Form.useForm();

  const Token = localStorage.getItem("Token");
  const clinic_id = localStorage.getItem("clinic_id");

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [Data, setData] = useState();

  const handleGetClinicDetails = () => {
    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/api/v1/clinic/${clinic_id}`, config)
      .then((res) => {
        const data = res.data.data;
        // Assuming the response data is in the correct structure
        setData(data.profile_picture_url);
        // Setting form fields with API data
        form.setFieldsValue({
          clinicName: data.clinic_name || "Smile Dental",
          firstName: data.first_name || "Sandy",
          lastName: data.last_name || "Wilder Cheng",
          workEmail: data.email || "sandy.cheng@smillie.com",
          phoneNo: data.phone_number,
          registrationType: data.register_type || "PMS + Claims",
          preferredPMS: data.preferred_pms || "SIKKA",
          currentLanguage: data.current_language || "English (US)",
          dateFormat: data.date_format || "mm-dd-yyyy",
          timeFormat: data.time_format || "hh:mm AM - hh:mm PM",
          timeZone: data.time_zone || "(UTC-08:00) Pacific Time (US & Canada)",
          useSystemSettings: data.system_settings || false,
        });
        setHasUnsavedChanges(false); // Reset unsaved changes indicator
        // message.success("Clinic details loaded successfully!");
      })
      .catch((err) => {
        message.error(
          err.response.data.data.errors || "Failed to load clinic details"
        );
      });
    uploadClinicProfile();
  };

  const handleUpdateClinicDetails = (values) => {
    const payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      clinic_name: values.clinicName,
      email: values.workEmail,
      phone_number: values.phoneNo,
      terms_accepted: true, // Modify if needed
      is_register_by: "user", // Modify based on actual value
      register_type: values.registrationType,
      preferred_pms: values.preferredPMS,
      current_language: values.currentLanguage,
      system_settings: values.useSystemSettings,
      date_format: values.dateFormat,
      time_format: values.timeFormat,
      time_zone: values.timeZone,
    };

    let config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    };

    axios
      .put(`${API_BASE_URL}/api/v1/clinic/clinic/${clinic_id}`, payload, config)
      .then((res) => {
        message.success("Clinic details updated successfully!");
        handleGetClinicDetails();
      })
      .catch((err) => {
        message.error(
          err.response.data.data.errors || "Failed to update clinic details"
        );
      });
  };

  const handleValuesChange = () => {
    setHasUnsavedChanges(true); // Mark as unsaved changes when any value changes
  };

  useEffect(() => {
    if (clinic_id !== null) {
      handleGetClinicDetails();
    }
  }, [clinic_id]);

  const handlePhoneChange = (e) => {
    const input = e.target.value;

    // Allow only digits, dashes, parentheses, and spaces
    const formatted = input
      .replace(/[^0-9()-\s]/g, "") // Remove invalid characters
      .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"); // Format as (XXX) XXX-XXXX

    // Update the form field value
    form.setFieldsValue({ phoneNo: formatted });
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file)); // Create a URL for the thumbnail preview
    } else {
      message.success("Please upload a valid JPG or PNG image.");
      setSelectedFile(null);
      setPreviewURL("");
    }
  };

  const [loading, setLoading] = useState(false);

  // const Token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const { setProData, handleGetClinicProPic } = UserAuth();

  const uploadClinicProfile = () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    axios
      .put(
        `${API_BASE_URL}/api/v1/clinic/${clinic_id}/update-profile-picture`,
        formData,
        config,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setProData();
        message.success("Profile picture uploaded successfully!");
        handleGetClinicProPic();
        setPreviewURL("");
        setSelectedFile(null);
        setLoading(false);
        // fetchPatients();
      })
      .catch((error) => {
        setLoading(false);
        message.error("Failed to upload profile picture.");
      });
  };

  return (
    <div className="clinic-profile-main-div">
      <Row className="w-100 d-flex">
        <Col md={24} xxl={6}>
          <div className="profile-pic-div">
            <input
              id="fileInput"
              type="file"
              accept=".jpg,.png"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Row>
              {previewURL ? (
                <div style={{ marginTop: "10px" }}>
                  <Spin size="large" spinning={loading}>
                    <img
                      src={previewURL}
                      alt="Thumbnail Preview"
                      style={{
                        objectFit: "cover",
                        // width: 100,
                        // height: 100,
                        width: 220,
                        borderRadius: "10px",
                      }}
                    />
                  </Spin>
                </div>
              ) : (
                <>
                  {Data ? (
                    <ClinicImageComponent
                      profile_picture_url={Data && Data?.split(".com/")[1]}
                    />
                  ) : (
                    <img
                      alt="profile picture"
                      src={propic}
                      style={{ width: 220 }}
                    />
                  )}
                </>
              )}
            </Row>
            <Row className="d-flex jc-end w-100">
              <img
                alt=""
                src={edit_icon}
                onClick={() => document.getElementById("fileInput").click()}
                style={{ width: 24, cursor: "pointer" }}
              />
            </Row>
          </div>
        </Col>
        <Col
          md={24}
          xxl={18}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleUpdateClinicDetails}
            onValuesChange={handleValuesChange}
          >
            <Row className="w-100" style={{ gap: "24px 0" }} gutter={[16, 24]}>
              <Col span={6}>
                <span className="input-header">
                  Clinic Name <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="clinicName"
                  rules={[
                    { required: true, message: "Clinic name is required" },
                  ]}
                >
                  <Input className="input" disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Firstname <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "First name is required" },
                  ]}
                >
                  <Input className="input" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Lastname <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="lastName"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input className="input" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Work Email <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="workEmail"
                  rules={[
                    { required: true, message: "Work email is required" },
                  ]}
                >
                  <Input className="input" disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Phone No. <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="phoneNo"
                  rules={[
                    { required: true, message: "Phone number is required" },
                    // ]}

                    // rules={[
                    // { required: true, message: "Phone number is required" },
                    {
                      pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                      message:
                        "Please enter a valid US phone number (e.g., (123) 456-7890)",
                    },
                  ]}
                >
                  {/* <PhoneInput
                    bordered={false}
                    country={"us"}
                    onlyCountries={["us", "in"]}
                    inputClass="input"
                  /> */}
                  <Input
                    className="input"
                    onChange={handlePhoneChange}
                    placeholder="Enter US phone number"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Registration Type <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="registrationType"
                  rules={[
                    {
                      required: true,
                      message: "Registration type is required",
                    },
                  ]}
                >
                  <Select
                    bordered={false}
                    className="select"
                    suffixIcon={<img src={moreIcon} alt="More Icon" />}
                  >
                    <Option value="PMS + Claims">PMS + Claims</Option>
                    <Option value="PMS Only">PMS Only</Option>
                    <Option value="Claims Only">Claims Only</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <span className="input-header">
                  Preferred PMS <span style={{ color: "#F00" }}>*</span>
                </span>
                <Form.Item
                  name="preferredPMS"
                  rules={[
                    { required: true, message: "Preferred PMS is required" },
                  ]}
                >
                  <Select
                    bordered={false}
                    className="select"
                    suffixIcon={<img src={moreIcon} alt="More Icon" />}
                  >
                    <Option value="SIKKA">SIKKA</Option>
                    <Option value="Dentrix">Dentrix</Option>
                    <Option value="Eaglesoft">Eaglesoft</Option>
                    <Option value="Open Dental">Open Dental</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="language-date-time-section">
              <Row>
                <span className="language-date-time-text">
                  Language, Date and Time
                </span>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={6}>
                  <Row className="w-100">
                    <span className="input-header">Current Language</span>
                    <Form.Item name="currentLanguage" className="w-100">
                      <Select
                        bordered={false}
                        className="select"
                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                      >
                        <Option value="English (US)">English (US)</Option>
                        <Option value="English (UK)">English (UK)</Option>
                        <Option value="Spanish">Spanish</Option>
                        <Option value="French">French</Option>
                      </Select>
                    </Form.Item>
                  </Row>
                </Col>
                {/* <Col>
                  <Row className="d-flex flex-col">
                    <span className="input-header">
                      Choose your preferred language
                    </span>
                    <Button className="choose-btn" type="primary">
                      Choose
                    </Button>
                  </Row>
                </Col> */}
              </Row>

              <Form.Item name="useSystemSettings" valuePropName="checked">
                <Checkbox className="checkbox">
                  Use your operating system settings for chosen language to
                  format dates, times, numbers, and measurements.
                </Checkbox>
              </Form.Item>

              <Row gutter={[16, 0]}>
                <Col span={6}>
                  <span className="input-header">Date Format</span>
                  <Form.Item name="dateFormat">
                    <Select
                      bordered={false}
                      className="select"
                      suffixIcon={<img src={moreIcon} alt="More Icon" />}
                    >
                      <Option value="mm-dd-yyyy">mm-dd-yyyy</Option>
                      <Option value="dd-mm-yyyy">dd-mm-yyyy</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <span className="input-header">Time Format</span>
                  <Form.Item name="timeFormat">
                    <Select
                      bordered={false}
                      className="select"
                      suffixIcon={<img src={moreIcon} alt="More Icon" />}
                    >
                      <Option value="hh:mm AM - hh:mm PM">
                        hh:mm AM - hh:mm PM
                      </Option>
                      <Option value="HH:mm - HH:mm">HH:mm - HH:mm</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <span className="input-header">Time Zone</span>
                  <Form.Item name="timeZone">
                    <Select
                      bordered={false}
                      className="select"
                      suffixIcon={<img src={moreIcon} alt="More Icon" />}
                    >
                      <Option value="UTC-05:00">
                        (UTC-05:00) Eastern Time (US & Canada)
                      </Option>
                      <Option value="UTC-06:00">
                        (UTC-06:00) Central Time (US & Canada)
                      </Option>
                      <Option value="UTC-07:00">
                        (UTC-07:00) Mountain Time (US & Canada)
                      </Option>
                      <Option value="UTC-08:00">
                        (UTC-08:00) Pacific Time (US & Canada)
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Row>

            <Row className="form-footer">
              {hasUnsavedChanges && (
                <Button
                  type="danger"
                  onClick={() => {
                    handleGetClinicDetails();
                  }}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              )}
              <Button type="primary" htmlType="submit" className="save-btn">
                Save
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ClinicProfile;
