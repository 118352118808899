import { Button, Col, Form, Input, message, Row, Select, Spin } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import add_alt from "../../../assets/add.png";
import default_propic from "../../../assets/default_propic.png";
import moreIcon from "../../../assets/expand_more.png";
import API_BASE_URL from "../../../env";
import './StaffOffCanvas.css';
import ProfileImage from "../../patients/patientdetails/ProfileImage";


const StaffOffCanvas = ({ show, setShow, mode, data, getStaffData }) => {
    const Token = localStorage.getItem('Token');
    const [addStaffForm] = Form.useForm();
    const [spinner, setSpinner] = useState(false);
    // const [days, setDays] = useState([]);
    // const [timings, setTimings] = useState({ startTime: null, endTime: null });
    const specializationOptions = [
        { value: "Orthodontics", label: "Orthodontics" },
        { value: "Endodontics", label: "Endodontics" },
        { value: "Periodontics", label: "Periodontics" },
        { value: "Prosthodontics", label: "Prosthodontics" },
        { value: "Pedodontics", label: "Pedodontics" },
    ];
    const branchOptions = [
        { value: "Toronto, ON M5H 3Y2", label: "Toronto, ON M5H 3Y2" },
        { value: "Ottawa, ON K1P 1J1", label: "Ottawa, ON K1P 1J1" },
        { value: "Calgary, AB T2P 1J9", label: "Calgary, AB T2P 1J9" },
        { value: "Vancouver, BC V6J 1P6", label: "Vancouver, BC V6J 1P6" },
    ];
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState("");

    useEffect(() => {
        // setDays([
        //     { label: "S", active: false },
        //     { label: "M", active: false },
        //     { label: "T", active: false },
        //     { label: "W", active: false },
        //     { label: "T", active: false },
        //     { label: "F", active: false },
        //     { label: "S", active: false },
        // ])
        if (show) {
            if (mode === 'edit') {
                addStaffForm.setFieldsValue({
                    staff_id: data?.provider_id,
                    staff_name: `${data?.firstname} ${data?.lastname ? data?.lastname : ''}`,
                    specialization: data?.specialty_code,
                    type: data?.provider_type,
                    age: data?.birthdate ? new Date().getFullYear() - new Date(data?.birthdate).getFullYear() : '',
                    phone: data?.phone,
                    email: data?.email,
                    branch: `${data?.city}, ${data?.state} ${data?.zipcode}`,
                    license_number: data?.tax_identification_number ? data?.tax_identification_number : "-"
                })
            }
        }

    }, [show, data])

    const parseAddress = (address) => {
        const addressRegex = /^([^,]+),\s*([A-Z]{2})\s*([A-Z0-9\s]+)$/;
        const match = address.match(addressRegex);

        if (match) {
            return {
                city: match[1].trim(),
                state: match[2].trim(),
                zipcode: match[3].trim(),
            };
        }

        return { city: '', state: '', zipcode: '' };
    };

    const calculateDOB = (age) => {
        const currentDate = new Date();
        const birthYear = currentDate.getFullYear() - age;

        const dob = new Date(birthYear, 0, 2);

        // Format the date as 'YYYY-MM-DDTHH:MM:SS'
        return dob.toISOString().split('.')[0];
    };

    const handleFileChange = (event) => {
        const profilePicture = event.target.files[0];
        if (
            profilePicture &&
            (profilePicture.type === "image/jpeg" ||
                profilePicture.type === "image/png")
        ) {
            setSelectedFile(profilePicture);
            setPreviewURL(URL.createObjectURL(profilePicture)); // Create a URL for the thumbnail preview
        } else {
            message.success("Please upload a valid JPG or PNG image.");
            setSelectedFile(null);
            setPreviewURL("");
        }
    };

    const handleClose = () => {
        setShow(false);
        addStaffForm.resetFields();
    };

    const handleStaffForm = async (values) => {
        setSpinner(true);

        const config = {
            headers: {
                'Authorization': `Bearer ${Token}`,
            }
        }

        const [firstname, lastname] = values.staff_name.split(' ');
        const { city, state, zipcode } = parseAddress(values.branch);
        const dob = calculateDOB(values.age);

        const fields = {
            firstname: firstname,
            lastname: lastname,
            specialty_code: values.specialization,
            provider_type: values.type,
            birthdate: dob,
            phone: values.phone,
            email: values.email,
            city: city,
            state: state,
            zipcode: zipcode,
            tax_identification_number: values.license_number,
            practice_id: '2',
            // cust_id: '',
            status: 'ACTIVE'
        }

        if (mode === 'add') {
            const formData = new FormData();
            formData.append("profilePicture", selectedFile);
            formData.append("providerRequest", JSON.stringify(fields)); // Send as a string

            axios
                .post(`${API_BASE_URL}/api/v1/providers/provider`, formData, config)
                .then((res) => {
                    setSpinner(false);
                    handleClose();
                    message.success('Staff added successfully');
                    getStaffData();
                })
                .catch((err) => {
                    setSpinner(false);
                    message.error(err.response.data.data.errors[0] || 'Failed to add staff, try again.');
                });
        } else {
            axios
                .put(`${API_BASE_URL}/api/v1/providers/update_provider_details_by_providerId?providerId=${values.staff_id}`, fields, config)
                .then((res) => {
                    setSpinner(false);
                    handleClose();
                    message.success('Staff updated successfully');
                    getStaffData();
                })
                .catch((err) => {
                    setSpinner(false);
                    message.error(err.response.data.data.errors[0] || 'Failed to update staff, try again.');
                });
        }
    }

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" className='off-canvas' width={840}>

            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Staff</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'} className="spinner">
                    <Form
                        form={addStaffForm}
                        onFinish={handleStaffForm}
                        className="offcanvas-form"
                    >
                        <div className="fields-container">
                            <Row
                                className="image-row"
                            >
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".jpg,.png"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                />
                                {previewURL ? (
                                    <div>
                                        <img
                                            key={previewURL} // Forces re-render
                                            src={previewURL || default_propic}
                                            alt="Profile"
                                            className="profile-pic-preview"
                                        />
                                    </div>
                                ) : data ? (
                                    <ProfileImage
                                        profile_picture_url={
                                            data?.profile_picture_url &&
                                            data?.profile_picture_url.split(".com/")[1]
                                        }
                                    />
                                ) : (
                                    <img
                                        alt=""
                                        src={default_propic}
                                        className="default-pic"
                                    />
                                )}
                                <Button
                                    type="text"
                                    onClick={() => document.getElementById("fileInput").click()}
                                >
                                    <img src={add_alt} alt="Add" className="add-icon" /> Photo
                                </Button>

                            </Row>
                            <Row className='form-row'>
                                {mode === 'edit' && (
                                    <Col className='form-column'>
                                        <span className="input-header">
                                            Staff ID <span className="asterisk">*</span>
                                        </span>
                                        <Form.Item
                                            name="staff_id"
                                        >
                                            <Input className="form-input" disabled />
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Staff Name <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="staff_name"
                                        rules={[
                                            { required: true, message: "Please enter the staff name" },
                                        ]}
                                    >
                                        <Input className="form-input" placeholder="Enter name" />
                                    </Form.Item>
                                </Col>
                                {mode === 'add' && (
                                    <Col className='form-column'></Col>
                                )}
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Specialization <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="specialization"
                                        rules={[
                                            { required: true, message: "Please select the specialization" }
                                        ]}
                                    >
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                            options={specializationOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">Type
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="type"
                                        rules={[
                                            { required: true, message: "Please select the type" }
                                        ]}
                                    >
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        >
                                            <Option value="Admin">Admin</Option>
                                            <Option value="Consultant">Consultant</Option>
                                            <Option value="Permanent">Permanent</Option>
                                            <Option value="Clinical Staff">Clinical Staff</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Age(Yrs) <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="age"
                                        rules={[
                                            { required: true, message: "Please enter the age" },
                                        ]}
                                    >
                                        <Input
                                            className="form-input"
                                            type="number"
                                            placeholder="Enter age"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Gender
                                    </span>
                                    <Form.Item
                                        name="gender"
                                        rules={[
                                            { required: false },
                                        ]}
                                    >
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select gender"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        >
                                            <Option value="male">Male</Option>
                                            <Option value="female">Female</Option>
                                            <Option value="other">Other</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Phone number(Primary){" "}
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            { required: true, message: "Please enter phone number" },
                                        ]}
                                    >
                                        <PhoneInput
                                            country={'us'}
                                            onlyCountries={['us', 'in']}
                                            inputClass="form-control"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Email <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: "Please enter email" },
                                            { type: "email", message: "Please enter a valid email" },
                                        ]}
                                    >
                                        <Input
                                            className="form-input"
                                            placeholder="Enter email address"
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col className='form-column'>
                                    <span className="input-header">Phone number</span>
                                    <Form.Item
                                        name="secondaryPhone"
                                        rules={[{ required: false }]}
                                    >
                                        <PhoneInput
                                            country={'us'}
                                            onlyCountries={['us', 'in']}
                                            inputClass="form-control"
                                        />
                                    </Form.Item>
                                </Col> */}
                            </Row>
                            {/* <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Email <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: "Please enter email" },
                                            { type: "email", message: "Please enter a valid email" },
                                        ]}
                                    >
                                        <Input
                                            className="form-input"
                                            placeholder="Enter email address"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Department
                                    </span>
                                    <Form.Item name="department" rules={[{ required: false }]}>
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        >
                                            <Option value="Pediatrics">Pediatrics</Option>
                                            <Option value="Orthodontics">Orthodontics</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">
                                        Branch <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="branch"
                                        rules={[{
                                            required: true, message: "Please select the branch"
                                        }]}>
                                        <Select
                                            className="form-input"
                                            bordered={false}
                                            placeholder="Select"
                                            suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                            options={branchOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                {/* <Col className='form-column'>
                                    <span className="input-header">Qualification</span>
                                    <Form.Item name="qualification">
                                        <Input
                                            className="form-input"
                                        />
                                    </Form.Item>
                                </Col> */}
                                <Col className='form-column'>
                                    <span className="input-header">License number
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="license_number"
                                        rules={[
                                            { required: true, message: "Please enter license number" }
                                        ]}
                                    >
                                        <Input
                                            className="form-input"
                                            disabled={mode === 'edit'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">Experience(Yrs)</span>
                                    <Form.Item name="experience">
                                        <Input
                                            className="form-input"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">License number
                                        <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="license_number">
                                        <Input
                                            className="form-input"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='form-row'>
                                <Col className='form-column'>
                                    <span className="input-header">Availability</span>
                                    <Form.Item
                                        className="staff-availability"
                                        name="businessDays"

                                    >
                                        {days.map((day, index) => (
                                            <div
                                                key={index}
                                                className={`day ${day.active ? 'active' : ''}`}
                                                onClick={() => handleDaySelect(index)}
                                            >
                                                {day.label}
                                            </div>
                                        ))}
                                    </Form.Item>
                                </Col>
                                <Col className='form-column'>
                                    <span className="input-header">Timings</span>
                                    <Form.Item name="timings" className='staff-timings'>
                                        <TimePicker
                                            suffixIcon={<img src={moreIcon} />}
                                            use12Hours
                                            format="h:mm A"
                                            onChange={handleStartTimeChange} />
                                        <span>TO</span>
                                        <TimePicker
                                            suffixIcon={<img src={moreIcon} />}
                                            use12Hours
                                            format="h:mm A"
                                            onChange={handleEndTimeChange} />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </div>

                        <div className="buttons-container">
                            <Button className="cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'add'}>
                                Save
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'edit'}>
                                Add
                            </Button>
                        </div>

                    </Form>

                </Spin>
            </Offcanvas.Body>
        </Offcanvas >
    )
}

export default StaffOffCanvas;