import { SearchOutlined } from '@ant-design/icons';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { Button, Divider, Dropdown, Input, Menu, message, Modal, Spin } from 'antd';
import axios from 'axios';
import parsePhoneNumberFromString from "libphonenumber-js";
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import addIcon from "../../assets/add.png";
import closeIcon from "../../assets/close.png";
import infoIcon from "../../assets/info.png";
import moreVertical from "../../assets/more_vert.png";
import sync_alt from "../../assets/sync.png";
import API_BASE_URL from '../../env';
import { routes_staff_details } from '../../utils/RoutingConts';
import CellWithOverflowTooltip from '../CellWithOverflowTooltip/CellWithOverflowTooltip';
import AppointmentOffcanvas from './staff-details/tabs/appointments/appointmentOffcanvas/AppointmentOffcanvas';
import './Staff.css';
import StaffOffCanvas from './staffOffCanvas/StaffOffCanvas';

const Staff = () => {
  const Token = localStorage.getItem("Token");
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "calc(100% - 82px)", width: "100%" }), []);
  const [rowData, setRowData] = useState();
  const [staffShow, setStaffShow] = useState(false);
  const [editStaffShow, setEditStaffShow] = useState(false);
  const [bookAppointmentShow, setBookAppointmentShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [syncTime, setSyncTime] = useState();
  const [staffDetails, setStaffDetails] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpen = () => {
    setStaffShow(true);
  };

  const handleSync = () => {
    setRowData([]);
    setLoading(true);

    const payload = {}

    axios
      .post(`${API_BASE_URL}/api/v1/providers/sync-and-save`, payload, config)
      .then((res) => {
        setLoading(false);
        refreshData();
      })
      .catch((err) => {
        message.error(err.response.data);
        setLoading(false);
      });
  }

  const handleBookAppointment = (data) => {
    setBookAppointmentShow(true);

    let id = data.provider_id;
    let fullName = `${data.firstname} ${data.lastname}`;
    let address = `${data.city}, ${data.state}, ${data.zipcode}`

    setStaffDetails({
      id: id,
      name: fullName,
      address: address
    });
  }

  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  useEffect(() => {
    refreshData();
  }, [setStaffShow]);

  const refreshData = () => {
    setRowData([]);
    setLoading(true);

    axios
      .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
      .then((res) => {

        const updatedData = res.data.data.map((item) => {
          if (item.firstname && item.lastname) {
            return { ...item, fullName: `${item.firstname} ${item.lastname}` };
          }
          return { ...item, fullName: item.firstname };
        });

        const last = res.data.data.length - 1;
        const time = res.data.data[last].updated_at;
        setSyncTime(moment(time).format("DD-MM-YYYY hh:mm A"));
        setRowData(updatedData);
        setLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data?.error || "Failed to load data, try again.");
        setLoading(false);
      });
  };

  const copyToClipboard = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        message.success("Email copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy email!");
      });
  };

  const formatPhoneNumber = (phoneNumber) => {
    // // Detect the country code based on the phone number prefix
    // const countryCode = phoneNumber.startsWith('91') ? 'IN' : 'US';

    // // Parse the phone number with the detected country code
    // const parsedNumber = parsePhoneNumberFromString(phoneNumber, countryCode);

    // // Format the phone number in international format with the +{countrycode}
    // return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;

    if (phoneNumber.startsWith('91')) {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'IN');
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    } else {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
      return parsedNumber ? parsedNumber.formatInternational() : phoneNumber;
    }
  };

  const editStaff = (data) => {
    setEditStaffShow(true);
    setStaffDetails(data);
  }

  const handleDeleteStaff = (data) => {
    setOpenDeleteModal(true);
    setStaffDetails(data);
  }

  const deleteStaff = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
      params: {
        providerId: staffDetails.provider_id
      }
    };

    axios
      .delete(`${API_BASE_URL}/api/v1/providers/delete_provider_details_by_providerId`, config)
      .then((res) => {
        message.success("Staff deleted successfully");
        setOpenDeleteModal(false);
        refreshData();
      })
      .catch((err) => {
        setOpenDeleteModal(false);
        message.error('Failed to delete the staff, try again.')
      })
  }

  const columnDefs = [
    {
      field: "provider_id",
      headerName: "SID",
      cellRenderer: (params) => {
        const { provider_id } = params.data;
        return (
          <div className="row-data-element">
            {provider_id}
          </div>
        );
      },
      flex: 0.75,
      minWidth: 80
    },
    {
      field: "clinic_name",
      headerName: "NAME & SPECIALIZATION",
      cellRenderer: (params) => {
        const { fullName, provider_id, firstname, lastname, specialty_code } = params.data;
        return (
          <div className="row-data-element">
            <div className="name-container">
              <div className="initials">
                {`${firstname?.charAt(0).toUpperCase()}${(lastname ? lastname : '').charAt(0).toUpperCase()}`}
              </div>
              <div className='name-specialization'>
                <div className="full-name">
                  <Link to={`${routes_staff_details}/${provider_id}`}><CellWithOverflowTooltip value={fullName} /></Link>
                </div>
                <span>{specialty_code ? specialty_code : '-'}</span>
              </div>
            </div>
          </div>
        );
      },
      // flex: 2,
      minWidth: 220
    },
    {
      field: "type",
      headerName: "TYPE",
      cellRenderer: (params) => {
        const { provider_type } = params.data;
        return (
          <div className="row-data-element">
            <div>{provider_type ? provider_type : 'Consultant'}</div>
          </div>
        );
      },
      minWidth: 150
    },
    {
      field: "age",
      headerName: "AGE",
      cellRenderer: (params) => {
        const { birthdate } = params.data;
        return (
          <div className="row-data-element">
            <div className="age">{birthdate ? `${new Date().getFullYear() - new Date(birthdate).getFullYear()} Years` : '-'}</div>
          </div>
        );
      },
      // flex: 1,
      minWidth: 140
    },
    {
      field: "contact",
      headerName: "CONTACT",
      cellRenderer: (params) => {
        const { phone, email } = params.data;
        return (
          <div className="row-data-element">
            <div className="phone">{formatPhoneNumber(phone)}</div>
            <div className="email" onClick={(() => copyToClipboard(email))}><CellWithOverflowTooltip value={email} /></div>
          </div>
        );
      },
      // flex: 2,
      minWidth: 200
    },
    {
      field: "created_at",
      headerName: "CREATED DATE & TIME",
      cellRenderer: (params) => {
        const { created_at } = params.data;
        const created_date = moment(created_at).format("DD MMM YYYY, h:mm A");
        return (
          <div className="row-data-element">
            <div className="created-date">{created_date}</div>
          </div>
        );
      },
      // flex: 1.5,
      minWidth: 220
    },
    {
      field: "status",
      headerName: "ACCOUNT STATUS",
      cellRenderer: (params) => {
        const { status } = params.data;
        let statusClass = "";

        if (status === "PENDING") {
          statusClass = "status-pending"; // Pending tab
        } else {
          statusClass = "status-active";  // Trial and subscriptions tab
        }

        return (
          <div className="row-data-element">
            <div className={`status ${statusClass}`}>
              {status === "PENDING" ? "PENDING" : "APPROVED"}
            </div>
          </div>
        );
      },
      // flex: 1.25,
      minWidth: 180
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      cellRenderer: (params) => {
        return (
          <div className='actions'>
            <div className='book-app-text' onClick={() => {
              handleBookAppointment(params.data)
            }}
            >
              Book Appointment
            </div>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="edit"
                    onClick={() => {
                      editStaff(params.data)
                    }}
                  >
                    Edit
                  </Menu.Item>
                  <Menu.Item
                    key="delete"
                    onClick={() => {
                      handleDeleteStaff(params.data)
                    }}
                  >
                    Delete
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <img src={moreVertical} alt="More" className="more" />
            </Dropdown>
          </div>
        );
      },
      pinned: 'right',  // This fixes the column to the right side
      suppressMovable: true,  // Prevent this column from being draggable
      lockPosition: true,
      sortable: false, // Optional: Disable sorting if not needed
      filter: false,
      resizable: false,
      minWidth: 200
      // maxWidth: 100
    }
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 2,
      resizable: true, // Enable column resizing
      autoHeight: true,
      wrapText: true
    };
  }, []);

  const rowHeight = 80;

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  const gridOptions = {
    suppressHorizontalScroll: false, // Enable horizontal scrolling
    overlayNoRowsTemplate: '<span class="custom-no-rows">No staffs to display</span>',
  }

  const onFirstDataRendered = (params) => {
    params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
  };

  // const onFilterTextBoxChanged = useCallback(() => {
  //   gridRef.current.api.setGridOption(
  //     "quickFilterText",
  //     document.getElementById("filter-text-box").value,
  //   );
  // }, []);

  // Search functionality
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = useMemo(() => {
    if (!searchQuery) return rowData; // No filtering if the search box is empty
    return rowData.filter((item) => {
      return (
        item.fullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.lastname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.createdAt?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.phone?.includes(searchQuery.toLowerCase())
      );
    });
  }, [searchQuery, rowData]); // Recalculate filtered data when `searchQuery` or `rowData` changes


  return (
    <div className="staff-container">
      <Helmet>
        <title>Staff Dashboard</title>
      </Helmet>

      <div className="data-container">
        <span className='sync-time'>Last updated: {syncTime}</span>
        <div className="data-header">
          {/* <Tabs
            defaultActiveKey={_doctors_tab}
            onChange={onTabChange}
            className="custom-tabs"
          >
            <TabPane tab="Doctors" key={_doctors_tab} />
            <TabPane tab="Directory" key={_directory_tab} />
          </Tabs> */}
          <div className='all-clinic-text'>
            All Clinic Staffs
          </div>
          <div className="search-container">
            <Button
              type="text"
              icon={<img alt="Sync" src={sync_alt} className='sync-icon' />}
              onClick={handleSync}
            >
              <span className="sync-text">Sync</span>
            </Button>
            <Button
              type="text"
              icon={<img alt='Add' src={addIcon} className='add-icon' />}
              onClick={handleOpen}>
              Staff
            </Button>
            <Input
              className="search-box"
              type="text"
              id="filter-text-box"
              placeholder="SEARCH"
              onChange={handleSearch}
              value={searchQuery}
              // onInput={onFilterTextBoxChanged}
              suffix={<SearchOutlined />}
            />
          </div>
        </div>
        <Divider plain></Divider>

        {loading && (
          <Spin className="spinner" />
        )}
        {!loading && (
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              rowData={filteredData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50, 100]}
              onFirstDataRendered={onFirstDataRendered}
              tooltipShowDelay={0}
              rowSelection={rowSelection}
              rowHeight={rowHeight}
              gridOptions={gridOptions}
            />
          </div>
        )}
      </div>

      <StaffOffCanvas show={staffShow} setShow={setStaffShow} mode={'add'} getStaffData={refreshData} />
      <StaffOffCanvas show={editStaffShow} setShow={setEditStaffShow} mode={'edit'} data={staffDetails} getStaffData={refreshData} />
      <AppointmentOffcanvas show={bookAppointmentShow} setShow={setBookAppointmentShow} mode={'add'} inputData={staffDetails} module={"staff"} />

      <Modal
        open={openDeleteModal}
        centered
        closable={false}
        footer={false}
      >
        <div className="modal-body">
          <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
          <div className="header">
            <img src={infoIcon} alt="info" className="info-icon" />
            Delete Staff
          </div>
          <div className="body">
            <span className="description">You are trying to delete a staff,
              this will remove all the staff data.
              Are you sure you want to continue?
            </span>

          </div>
          <div className="buttons-container">
            <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
            <Button className="yes" onClick={deleteStaff}>Yes</Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Staff