import { Col, Divider, message, Row, Spin, Tabs } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from 'react-router-dom';
import backIcon from "../../../assets/arrow_back.png";
import chevron_right from "../../../assets/chevron_right.png";
import copyIcon from "../../../assets/content_copy.png";
import editIcon from "../../../assets/edit.png";
import emailIcon from "../../../assets/email.png";
import moreIcon from "../../../assets/more_horiz.png";
import phoneIcon from "../../../assets/phone_iphone.png";
import API_BASE_URL from '../../../env';
import { routes_staffs } from '../../../utils/RoutingConts';
import CellWithOverflowTooltip from '../../CellWithOverflowTooltip/CellWithOverflowTooltip';
import StaffOffCanvas from '../staffOffCanvas/StaffOffCanvas';
import './StaffDetails.css';
import Appointments from './tabs/appointments/Appointments';
import Notes from './tabs/notes/Notes';
import Patients from './tabs/patients/Patients';

const StaffDetails = () => {
    const Token = localStorage.getItem('Token');
    const { staffId } = useParams();
    const [staffDetails, setStaffDetails] = useState([]);
    const navigate = useNavigate();
    const [showDetails, setShowDetails] = useState(false);
    const [show, setShow] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [loading, setLoading] = useState(true);
    const handleOpen = () => {
        setShow(true);
    };

    useEffect(() => {
        getStaffData();
    }, [staffId, setStaffDetails]);

    const getStaffData = () => {
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                providerId: staffId
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/providers/get-providers`, config)
            .then(res => {
                setStaffDetails(res?.data[0]);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    };

    const days = [
        { label: "S", active: false },
        { label: "M", active: true },
        { label: "T", active: true },
        { label: "W", active: true },
        { label: "T", active: true },
        { label: "F", active: true },
        { label: "S", active: true },
    ];

    const toggleDetails = () => {
        setShowDetails((prev) => !prev);
    };

    const copyToClipboard = (email) => {
        navigator.clipboard.writeText(email)
            .then(() => {
                message.success("Email copied to clipboard!");
            })
            .catch(() => {
                message.error("Failed to copy email!");
            });
    };

    const onChange = (key) => {
        setActiveKey(key);
    };

    const items = [
        {
            key: "1",
            label: (
                <Row className="d-flex w-100 ai-center jc-between">
                    <Col>
                        <span className="side-tabs-label">Appointments</span>
                    </Col>
                    {activeKey === "1" && (
                        <Col>
                            <img alt="" src={chevron_right} className="right-arrow" />
                        </Col>
                    )}
                </Row>
            ),
            children: <Appointments activeKey={activeKey} />,
        },
        {
            key: "2",
            label: (
                <Row className="d-flex w-100 ai-center jc-between">
                    <Col>
                        <span className="side-tabs-label">Patients</span>
                    </Col>
                    {activeKey === "2" && (
                        <Col>
                            <img alt="" src={chevron_right} className="right-arrow" />
                        </Col>
                    )}
                </Row>
            ),
            children: <Patients />,
        },
        {
            key: "3",
            label: (
                <Row className="d-flex w-100 ai-center jc-between">
                    <Col>
                        <span className="side-tabs-label">Notes</span>
                    </Col>
                    {activeKey === "3" && (
                        <Col>
                            <img alt="" src={chevron_right} className="right-arrow" />
                        </Col>
                    )}
                </Row>
            ),
            children: <Notes />,
        }
    ];

    return (
        <div className='staff-details-container'>
            <Helmet>
                <title>Staff Details</title>
            </Helmet>

            {loading && (
                <Spin className="spinner" />
            )}

            {!loading && staffDetails && (
                <div className='staff-data-view'>
                    <div className='staff-data-header'>
                        <img src={backIcon} alt='Back' className='back-icon' onClick={() => navigate(routes_staffs)} />
                        <div className="initials">
                            {`${staffDetails?.firstname.charAt(0).toUpperCase()}${staffDetails?.lastname.charAt(0).toUpperCase()}`}
                        </div>
                        <div className='details-section'>
                            <Row className='details-row'>
                                <Col className='details-column'>
                                    <span className='staff-name'><CellWithOverflowTooltip value={`${staffDetails?.firstname} ${staffDetails?.lastname}`} /></span>
                                    <span className='staff-designation'>{staffDetails?.specialty_code ? staffDetails?.specialty_code : 'Pediatric Dentist'}</span>
                                </Col>
                                <Col className='details-column'>
                                    <span className='details-header'>department</span>
                                    <span className='staff-department'>{staffDetails?.department ? staffDetails?.department : '-'}</span>
                                </Col>
                                <Col className='details-column'>
                                    <span className='details-header'>type</span>
                                    <span className='staff-type'>{staffDetails?.provider_type ? staffDetails?.provider_type : '-'}</span>
                                </Col>
                                <Col className='availability-details'>
                                    <span className='details-header'>availability</span>
                                    <div className='staff-availability'>
                                        {/* Render each day */}
                                        {days.map((day, index) => (
                                            <div
                                                key={index}
                                                className={`day ${day.active ? 'active' : ''}`}
                                            >
                                                {day.label}
                                            </div>
                                        ))}
                                        {/* Change Link */}
                                        {/* <a>
                                        Change
                                    </a> */}
                                    </div>
                                </Col>

                            </Row>
                            {/* Details Section */}
                            {showDetails && (
                                <Row className='details-row'>
                                    <Col className='details-column'>
                                        <span className='details-header'>contact</span>
                                        <div className='contact-info'>
                                            <span>
                                                <img src={phoneIcon} alt='Phone' className='phone-icon' />
                                                {staffDetails?.phone}
                                            </span>
                                            <span>
                                                <img src={emailIcon} alt='Email' className='email-icon' />
                                                <CellWithOverflowTooltip value={staffDetails?.email ? staffDetails?.email : '-'} />
                                                {staffDetails?.email && (
                                                    <img src={copyIcon} alt='Copy' className='copy-icon' onClick={() => copyToClipboard(staffDetails?.email)} />
                                                )}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col className='details-column'>
                                        <span className='details-header'>branch</span>
                                        <div className='staff-address'>
                                            <span>{staffDetails?.city}</span>
                                            <span>{staffDetails?.state} {staffDetails?.zipcode}</span>
                                        </div>
                                    </Col>
                                    <Col className='details-column'>
                                        <span className='details-header'>qualification</span>
                                        <span className='staff-type'>{staffDetails?.qualification ? staffDetails?.qualification : '-'} </span>
                                    </Col>
                                    <Col className='availability-details'>
                                        <span className='details-header'>timings</span>
                                        <span className='staff-timings'>10:00 AM - 7:00 PM MON - SAT
                                            {/* <a>
                                            Change
                                        </a> */}
                                        </span>
                                    </Col>
                                </Row>
                            )}

                            {showDetails && (
                                <Row className='details-row'>
                                    <Col className='details-column'>
                                        <span className='details-header'>EXPERIENCE</span>
                                        <span className='staff-experience'>-</span>
                                    </Col>
                                    <Col className='details-column'>
                                        <span className='details-header'>license no</span>
                                        <span className='staff-type'>{staffDetails?.tax_identification_number ? staffDetails?.tax_identification_number : 'XX - 00000 - XX'}</span>
                                    </Col>
                                    <Col className='details-column'>
                                        <span className='details-header'>STATUS</span>
                                        <span className='staff-status'>{staffDetails?.status}</span>
                                    </Col>
                                </Row>
                            )}
                        </div>

                        <div className='options'>
                            <img src={editIcon} alt='Edit' className='edit-icon' onClick={handleOpen} />
                            <img src={moreIcon} alt='More' className='more-icon' onClick={toggleDetails} />
                        </div>
                    </div>

                    <StaffOffCanvas show={show} setShow={setShow} mode={'edit'} data={staffDetails} getStaffData={getStaffData} />

                    <Divider className='divider'></Divider>

                    <div className={`tabs-div ${showDetails ? 'active' : ''}`}>
                        <Tabs
                            tabPosition="left"
                            defaultActiveKey="1"
                            activeKey={activeKey}
                            items={items}
                            onChange={onChange}
                            type="card"
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default StaffDetails;