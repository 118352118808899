import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Select,
  Tabs,
} from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import add_alt from "../../../assets/add.png";
import backIcon from "../../../assets/arrow_back.png";
import chevron_right from "../../../assets/chevron_right.png";
import copyIcon from "../../../assets/copyicon.png";
import default_propic from "../../../assets/default_propic.png";
import editIcon from "../../../assets/edit.png";
import emailIcon from "../../../assets/email.png";
import moreIcon from "../../../assets/more_horiz.png";
import phoneIcon from "../../../assets/phone_iphone.png";
import profileIcon from "../../../assets/profile_img.png";
import API_BASE_URL from "../../../env";
import { routes_patients } from "../../../utils/RoutingConts";
import CellWithOverflowTooltip from "../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import PatientAppointments from "../tabs/appointment/PatientAppointments";
import EligibilityCheck from "../tabs/eligibility-check/EligibilityCheck";
import InsuranceDetails from "../tabs/insuance-details/InsuranceDetails";
import MedicalHistory from "../tabs/medical-history/MedicalHistory";
import ProfileImage from "./ProfileImage";
import ProfilePicture from "./ProfilePicture";
import ClaimDetails from "../tabs/claim-details/ClaimDetails";

const PatientDetails = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("activeKey") ? localStorage.getItem("activeKey") : "1"
  );
  const [patientData, setPatientData] = useState(null);

  const [showMoreData, setShowMoreData] = useState(false);

  const Token = localStorage.getItem("Token");
  const config = {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  };

  const fetchPatientData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId?patientId=${patientId}`,
        config
      );
      setPatientData(response.data);
    } catch (error) {
      console.error("Failed to fetch patient data:", error);
    }
  };
  // Fetch patient data from API
  useEffect(() => {
    fetchPatientData();
  }, []);

  const onChange = (key) => {
    setActiveKey(key);
    localStorage.setItem("activeKey", key);
  };

  const items = [
    {
      key: "1",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Appointments</span>
          </Col>
          {activeKey === "1" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <PatientAppointments activeKey={activeKey} />,
    },
    {
      key: "2",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Eligibility Check</span>
          </Col>
          {activeKey === "2" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <EligibilityCheck />,
    },
    {
      key: "3",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Medical History</span>
          </Col>
          {activeKey === "3" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <MedicalHistory patientData={patientData} />,
    },
    {
      key: "4",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Insurance Details</span>
          </Col>
          {activeKey === "4" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <InsuranceDetails patientData={patientData} />,
    },
    {
      key: "5",
      label: (
        <Row className="d-flex w-100 ai-center jc-between">
          <Col>
            <span className="side-tabs-label">Claim Details</span>
          </Col>
          {activeKey === "5" && (
            <Col>
              <img alt="" src={chevron_right} style={{ width: 24 }} />
            </Col>
          )}
        </Row>
      ),
      children: <ClaimDetails />,
    },
  ];

  const handleCancel = () => {
    form.resetFields(); // Clear form fields
    setDrawerVisible(false);
  };

  const [form] = Form.useForm();
  const [editRecord, setEditRecord] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const closeDrawer = () => {
    setDrawerVisible(false);
    setEditRecord(null);
    form.resetFields();
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    setDrawerVisible(true);
    form.setFieldsValue({
      ...record,
      name:
        record.firstname && record.lastname
          ? `${record.firstname} ${record.lastname}`
          : record.firstname,
      addressLine1: record.addressLine1 ? record.addressLine1 : null,
      age: new Date().getFullYear() - new Date(record.birthdate).getFullYear(),
      primaryPhone: record.cell,
      // dob: moment(record.birthdate).format("YYYY-MM-DD"),
      dob: record.birthdate ? dayjs(record.birthdate) : null,
      referredBy: record.patientReferral ? record.patientReferral : null,
      emergencyContactName: record.guarantorFirstName
        ? record.guarantorFirstName
        : null,
      emergencyContactPhone: record.preferredContact
        ? record.preferredContact
        : null,
    });
  };

  const [loading, setLoading] = useState(false);
  const [disbaledButton, setdisbaledButton] = useState(false);

  const handleSubmit = (values) => {
    setdisbaledButton(true);
    const payload = {
      firstname: values.name.split(" ")[0],
      middlename: values.middlename || "",
      lastname: values.name.split(" ")[1],
      preferredName: values.name || "",
      salutation: values.salutation || "",
      birthdate: values.dob.format("YYYY-MM-DDTHH:mm:ss"),
      status: "Active",
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2 || "",
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      cell: values.primaryPhone,
      email: values.email,
      firstVisit: "",
      lastVisit: "",
      providerId: "1",
      practiceId: "1",
      preferredContact: values.emergencyContactPhone,
      preferredCommunicationMethod: "",
      custId: "",
      otherReferral: values.otherReferral || "",
      patientReferral: values.referredBy || "",
      referredOut: "",
      guarantorId: "",
      guarantorFirstName: values.emergencyContactName || "",
      guarantorLastName: "",
    };

    if (editRecord) {
      axios
        .put(
          `${API_BASE_URL}/api/v1/patients/update_patient?patientId=${editRecord.patientId}`,
          payload,
          config
        )
        .then((res) => {
          message.success("Patient updated successfully!");
          setEditRecord(null);
          setDrawerVisible(false);
          form.resetFields();
          setdisbaledButton(false);
          fetchPatientData();
          if (res.data.data.patientId) {
            const patient_id = res.data.data.patientId;
            if (!selectedFile) {
              // message.warning("Please select an image before submitting.");
              return;
            }

            const formData = new FormData();
            formData.append("file", selectedFile);
            axios
              .put(
                `${API_BASE_URL}/api/v1/patients/patientId/update-profile-picture?patientId=${patient_id}`,
                formData,
                config,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                message.success("Profile picture uploaded successfully!");
                setPreviewURL("");
                setSelectedFile(null);
                setLoading(false);
                fetchPatientData();
                setdisbaledButton(false);
              })
              .catch((error) => {
                setLoading(false);
                message.error("Failed to upload profile picture.");
              });
          }
        })
        .catch((error) => {
          message.error("Failed to add patient.");
        });
    } else {
      axios
        .post(`${API_BASE_URL}/api/v1/patients/add-patient`, payload, config)
        .then((res) => {
          message.success("Patient added successfully!");
          setDrawerVisible(false);
          form.resetFields();
          fetchPatientData();
          setdisbaledButton(false);
        })
        .catch((err) => {
          message.error(err.response?.data.data.errors[0]);
        });
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      setPreviewURL(URL.createObjectURL(file)); // Create a URL for the thumbnail preview
    } else {
      message.success("Please upload a valid JPG or PNG image.");
      setSelectedFile(null);
      setPreviewURL("");
    }
  };

  return (
    <div className="patient-details-tab-main-div">
      <Row className="w-100 patient-details-row" gutter={[24, 0]}>
        {/* Back Icon */}
        <Col>
          <img
            src={backIcon}
            alt="Back"
            className="back-icon"
            onClick={() => navigate(routes_patients)}
          />
        </Col>

        {/* Profile Picture */}
        <Col>
          {patientData?.profilePictureUrl ? (
            <ProfilePicture
              profile_picture_url={
                patientData?.profilePictureUrl &&
                patientData?.profilePictureUrl.split(".com/")[1]
              }
            />
          ) : (
            <img src={profileIcon} alt="Profile Pic" className="profile-pic" />
          )}
        </Col>

        {/* Patient Info */}
        <Col style={{ gap: 20, display: "flex", flexDirection: "column" }}>
          <Row gutter={[8, 0]}>
            <Col>
              <div className="data-column">
                <span className="name">
                  {patientData
                    ? patientData.lastname
                      ? `${patientData.firstname} ${patientData.lastname}`
                      : patientData.firstname
                    : "Loading..."}
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">Gender</span>
                <span className="data">Female</span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">date of birth & age</span>
                <span className="data">
                  {patientData
                    ? new Date(patientData.birthdate).toLocaleDateString()
                    : "Loading..."}
                </span>
                <span className="data">
                  {patientData
                    ? `${
                        new Date().getFullYear() -
                        new Date(patientData.birthdate).getFullYear()
                      } Years`
                    : ""}
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">address</span>
                <span className="data">
                  <CellWithOverflowTooltip
                    value={
                      patientData
                        ? `${patientData.addressLine1}, ${patientData.city}, ${patientData.state}, ${patientData.zipcode}`
                        : "Loading..."
                    }
                  />
                </span>
              </div>
            </Col>
            <Col>
              <div className="data-column">
                <span className="data-header">referred by</span>
                <span className="data-link">
                  {patientData
                    ? `${patientData.guarantorFirstName} ${patientData.guarantorLastName}`
                    : "Loading..."}
                </span>
              </div>
            </Col>
          </Row>

          {showMoreData && (
            <Row gutter={[8, 0]}>
              <Col>
                <div className="data-column">
                  <span className="data-header">contact</span>
                  <span className="data-blue d-flex ai-center">
                    <img src={phoneIcon} alt="Phone" className="phone-icon" />
                    {patientData ? patientData.cell : "Loading..."}
                  </span>
                  <span
                    className="data-link d-flex ai-center"
                    style={{ gap: 8 }}
                  >
                    <img src={emailIcon} alt="Email" className="email-icon" />
                    <CellWithOverflowTooltip
                      value={patientData ? patientData.email : "Loading..."}
                    />
                    <img src={copyIcon} alt="copy" className="copy-icon" />
                  </span>
                </div>
              </Col>
              <Col>
                <div className="data-column">
                  <span className="data-header">emergency contact</span>
                  <span className="data-blue d-flex ai-center">
                    <img src={phoneIcon} alt="Phone" className="phone-icon" />
                    +1 802 225-5334
                  </span>
                  <span className="data">Jennifer Diana</span>
                </div>
              </Col>
              <Col>
                <div className="data-column">
                  <span className="data-header">LAst Visit & Treatment</span>
                  <span className="data">05 Jun 2021 11:00 AM </span>
                  <span className="data">Tooth Scaling</span>
                </div>
              </Col>
              <Col>
                <div className="data-column">
                  <span className="data-header">No. Of Appointments</span>
                  <span className="data">02</span>
                </div>
              </Col>
              <Col>
                <div className="data-column">
                  <span className="data-header">STATUS</span>
                  <div className="status">
                    <span className="data">
                      {patientData ? patientData.status : "Loading..."}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Col>

        {/* Options */}
        <Col>
          <div className="options">
            <img
              src={editIcon}
              alt="Edit"
              className="edit-icon"
              onClick={() => handleEdit(patientData)}
            />
            <img
              src={moreIcon}
              alt="More"
              className="more-icon"
              onClick={() => setShowMoreData(!showMoreData)}
            />
          </div>
        </Col>
      </Row>
      <div className={`tabs-div ${showMoreData ? "active" : ""}`}>
        <Tabs
          tabPosition="left"
          defaultActiveKey="1"
          activeKey={activeKey}
          items={items}
          onChange={onChange}
          type="card"
        />
      </div>

      <Drawer
        className="add-patient-modal"
        closeIcon={false}
        width={720}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className="add-patient-modal-main-div w-100">
          <Row className="w-100 d-flex jc-end">
            <CloseOutlined style={{ fontSize: 24 }} onClick={closeDrawer} />
          </Row>
          <Row>
            <span className="add-patient-text">
              {editRecord ? "Edit Patient" : "Add Patient"}
            </span>
          </Row>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="w-100"
          >
            <Row gutter={[0, 24]}>
              {/* <Row
              
                className="w-100 d-flex ai-end"
                style={{ padding: "20px 0 0 0" }}
              >
                <img
                  alt=""
                  src={default_propic}
                  style={{
                    width: 58,
                    height: 58,
                  }}
                />
                <Button type="text">
                  <img src={add_alt} alt="" style={{ width: 24 }} /> Photo
                </Button>
              </Row> */}
              {editRecord && (
                <Row
                  className="w-100 d-flex ai-end"
                  style={{ padding: "20px 0 0 0" }}
                >
                  <input
                    id="fileInput"
                    type="file"
                    accept=".jpg,.png"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {previewURL ? (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={previewURL}
                        alt="Thumbnail Preview"
                        style={{
                          objectFit: "cover",
                          width: 100,
                          borderRadius: "10px",
                          height: 100,
                        }}
                      />
                    </div>
                  ) : patientData && editRecord?.profilePictureUrl ? (
                    <ProfileImage
                      profile_picture_url={
                        editRecord?.profilePictureUrl &&
                        editRecord?.profilePictureUrl.split(".com/")[1]
                      }
                    />
                  ) : (
                    <img
                      alt=""
                      src={default_propic}
                      style={{
                        borderRadius: "10px",
                        width: 58,
                        height: 58,
                      }}
                    />
                  )}
                  <Button
                    type="text"
                    style={{ margin: "0 0 0 10px" }}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <img src={add_alt} alt="" style={{ width: 24 }} /> Photo
                  </Button>
                </Row>
              )}
              <Row gutter={[20, 24]} className="w-100">
                <Col span={12}>
                  <span className="input-header">
                    Name <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "Please enter the name" },
                    ]}
                  >
                    <Input
                      className="input"
                      disabled={editRecord}
                      placeholder="Enter name"
                    />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <span className="input-header">
                    Last Name <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="lastname"
                    rules={[
                      { required: true, message: "Please enter the name" },
                    ]}
                  >
                    <Input
                      className="input"
                      disabled={editRecord}
                      placeholder="Enter name"
                    />
                  </Form.Item>
                </Col> */}
                <Col span={12}>
                  <span className="input-header">
                    {editRecord && (
                      <>
                        Patient ID <span style={{ color: "#F00" }}>*</span>
                      </>
                    )}
                  </span>
                  {editRecord && (
                    <Form.Item name="patientId">
                      <Input disabled className="input" />
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  <span className="input-header">
                    Age(Yrs) <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="age"
                    rules={[
                      { required: true, message: "Please enter the age" },
                    ]}
                  >
                    <Input
                      className="input"
                      type="number"
                      disabled={editRecord}
                      placeholder="Enter age"
                    />
                  </Form.Item>
                </Col>
                {!editRecord && (
                  <Col span={12}>
                    <span className="input-header">
                      Gender <span style={{ color: "#F00" }}>*</span>
                    </span>
                    <Form.Item
                      name="gender"
                      rules={[
                        { required: true, message: "Please select gender" },
                      ]}
                    >
                      <Select
                        className="input"
                        bordered={false}
                        placeholder="Select gender"
                      >
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <span className="input-header">
                    Phone number(Primary){" "}
                    <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="primaryPhone"
                    rules={[
                      { required: true, message: "Please enter phone number" },
                    ]}
                  >
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us", "in"]}
                      inputClass="form-control"
                      className="input"
                    />
                  </Form.Item>
                </Col>
                {!editRecord && (
                  <Col span={12}>
                    <span className="input-header">Phone number</span>
                    <Form.Item
                      name="secondaryPhone"
                      rules={[{ required: false }]}
                    >
                      <PhoneInput
                        country={"us"}
                        onlyCountries={["us", "in"]}
                        className="input"
                        inputClass="form-control"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={12}>
                  <span className="input-header">
                    Date of birth <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="dob"
                    rules={[
                      {
                        required: true,
                        message: "Please select date of birth",
                      },
                    ]}
                  >
                    <DatePicker
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      }
                      className="input"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="input-header">
                    Email <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Please enter email" },
                      { type: "email", message: "Please enter a valid email" },
                    ]}
                  >
                    <Input
                      className="input"
                      placeholder="Enter email address"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="input-header">
                    Address <span style={{ color: "#F00" }}>*</span>
                  </span>
                  <Form.Item
                    name="addressLine1"
                    rules={[
                      { required: true, message: "Please enter address" },
                    ]}
                  >
                    <Input.TextArea
                      className="input"
                      rows={2}
                      placeholder="Enter address"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="input-header">Referred by</span>
                  <Form.Item name="referredBy" rules={[{ required: false }]}>
                    <Select
                      className="input"
                      bordered={false}
                      placeholder="Select referral source"
                    >
                      <Option value="doctor">Doctor</Option>
                      <Option value="friend">Friend</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="input-header">Emergency contact name</span>
                  <Form.Item name="emergencyContactName">
                    <Input
                      className="input"
                      placeholder="Enter emergency contact name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <span className="input-header">
                    Emergency contact phone number
                  </span>
                  <Form.Item name="emergencyContactPhone">
                    <PhoneInput
                      country={"us"}
                      onlyCountries={["us", "in"]}
                      className="input"
                      inputClass="form-control"
                    />
                    {/* <Input
                    
                      prefix={
                        <div className="d-flex ai-center" style={{ gap: 7 }}>
                          <img
                            alt=""
                            src={usflag}
                            style={{ width: 36, height: 36 }}
                          />
                          <span>+1</span>
                        </div>
                      }
                      onKeyPress={(e) => {
                        const charCode = e.which || e.keyCode;
                        if (!(charCode >= 48 && charCode <= 57)) {
                          e.preventDefault();
                        }
                      }}
                      bordered={false}
                      onPaste={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onCut={(e) => e.preventDefault()}
                      className="input"
                    /> */}
                  </Form.Item>
                </Col>
              </Row>

              <Row className="w-100 d-flex jc-end" gutter={[16, 0]}>
                <Col>
                  <Button key="cancel" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    htmlType="submit"
                    type="primary"
                    // onClick={() => form.submit()}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </div>
      </Drawer>
    </div>
  );
};

export default PatientDetails;
