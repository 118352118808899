import { Button, DatePicker, Form, Input, message, Row, Select, Spin, TimePicker } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import calendarIcon from "../../../../../../assets/calendar.png";
import moreIcon from "../../../../../../assets/expand_more.png";
import API_BASE_URL from "../../../../../../env";
import './AppointmentOffcanvas.css';
import { useParams } from "react-router-dom";

const AppointmentOffcanvas = ({ show, setShow, mode, module, inputData, selectedAppointment, getAppointments }) => {
    const Token = localStorage.getItem("Token");
    const { patientId } = useParams();
    const [appointmentForm] = Form.useForm();
    const [spinner, setSpinner] = useState(false);
    const [time, setTime] = useState();
    const [patients, setPatients] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const appointmentOptions = [
        { value: "Dental Pain/Discomfort", label: "Dental Pain/Discomfort" },
        { value: "Dental Cleaning", label: "Dental Cleaning" },
        { value: "Dental Fillings", label: "Dental Fillings" },
        { value: "Tooth Extraction", label: "Tooth Extraction" },
        { value: "Root Canal Treatment", label: "Root Canal Treatment" },
        { value: "Orthodontic Consultation", label: "Orthodontic Consultation" },
    ]

    useEffect(() => {
        if (show) {
            if (mode === 'edit') {
                appointmentForm.setFieldsValue({
                    appointment_sr_no: selectedAppointment.id,
                    description: selectedAppointment.description,
                    type: selectedAppointment.type,
                    provider_id: module === 'patient' ? selectedAppointment.provider_id : inputData.name,
                    practice_id: selectedAppointment.practice_id,
                    patient_id: selectedAppointment.patient_id,
                    patient_name: selectedAppointment.patient_name,
                    date: selectedAppointment.date ? dayjs(selectedAppointment.date) : null,
                    time: selectedAppointment.time ? dayjs(selectedAppointment.time, 'HH:mm') : null,
                    length: selectedAppointment.length
                })
                getPatientsOrStaff();
            }
            else {
                getPatientsOrStaff();
            }
        }
    }, [show, inputData]);

    const getPatientsOrStaff = () => {
        if (module === 'staff') {
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                params: {
                    providerId: inputData?.id
                }
            };

            axios
                .get(`${API_BASE_URL}/api/v1/patients/get-patients`, config)
                .then((res) => {
                    // const names = res.data.map((patient) => `${patient.firstname} ${patient.lastname}`);
                    setPatients(res?.data);
                })
                .catch((error) => {
                    message.error(error.response.data.status_message);
                })
        } else {
            const updatedData = inputData.map((item) => ({
                ...item,
                name: item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname,
            }));
            setPatients(updatedData);

            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`,
                }
            };

            axios
                .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
                .then((res) => {
                    // const names = res.data.map((patient) => `${patient.firstname} ${patient.lastname}`);
                    setProviders(res?.data.data);

                })
                .catch((error) => {
                    message.error(error.response.data.status_message);
                })

        }
    }

    const handleClose = () => {
        setShow(false);
        appointmentForm.resetFields();
    };

    const handleStartTimeChange = (time, timeString) => {
        setTime(timeString);
    };

    const handleAppointmentForm = () => {
        setSpinner(true);
        const formValues = appointmentForm.getFieldsValue();
        const formattedDate = formValues.date
            ? new Date(formValues.date.format('YYYY-MM-DD')).toISOString()
            : null;
        const fields = {
            description: formValues.description,
            type: formValues.type,
            provider_id: module === "staff" ? inputData?.id : formValues.provider_id,
            practice_id: '2',
            patient_id: module === "staff" ? formValues.patient_id : inputData[0].patientId,
            patient_name: module === "staff" ? getPatientName(formValues.patient_id) : patients[0].name,
            date: formattedDate,
            time: convertTo24HourFormat(),
            length: formValues.length
        }

        if (mode === 'add') {
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }

            axios
                .post(`${API_BASE_URL}/api/v1/Appointments/createAppointments`, fields, config)
                .then((res) => {
                    message.success('Appointment added successfully');
                    setSpinner(false);
                    handleClose();
                    getAppointments();
                })
                .catch(err => {
                    setSpinner(false);
                    message.error(err.response?.data.errors);
                })
        }
        else {
            const config = {
                headers: {
                    Authorization: `Bearer ${Token}`
                },
                params: {
                    appointmentSrNo: selectedAppointment.id
                }
            }

            axios
                .put(`${API_BASE_URL}/api/v1/Appointments/update_appointments_by_appointmentId`, fields, config)
                .then((res) => {
                    message.success('Appointment updated successfully');
                    setSpinner(false);
                    handleClose();
                    getAppointments();
                })
                .catch(err => {
                    setSpinner(false);
                    message.error(err.response.data.errors);
                })
        }
    }

    const getPatientName = (id) => {
        const patient = patients.length > 0 ? patients.find(patient => patient.patientId === id) : null;
        return patient ? `${patient.firstname} ${patient.lastname}` : 'Unknown Patient';
    }

    const convertTo24HourFormat = () => {
        if (time) {
            const [hours, minutesPart] = time.split(':');
            const [minutes, period] = minutesPart.split(' ');

            let hours24 = parseInt(hours, 10);

            if (period.toLowerCase() === 'pm' && hours24 !== 12) {
                hours24 += 12;
            } else if (period.toLowerCase() === 'am' && hours24 === 12) {
                hours24 = 0;
            }

            // Format hours and minutes to ensure two digits
            const formattedHours = hours24.toString().padStart(2, '0');
            const formattedMinutes = minutes.padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}`;
        }
        else {
            return selectedAppointment.time
        }
    };

    const handleDoctorChange = (value) => {
        const selectedStaff = providers.find((item) => item.provider_id === value);
        if (selectedStaff) {
            const formattedAddress = `${selectedStaff.city || ''}, ${selectedStaff.state || ''}, ${selectedStaff.zipcode || ''}`;
            setSelectedAddress(formattedAddress.trim().replace(/^,|,$/g, '')); // Clean up empty fields
        } else {
            setSelectedAddress(null);
        }
    };

    return (
        <Offcanvas show={show} onHide={handleClose} placement="end" className='appointment-off-canvas'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Appointment</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'}>
                    <Form
                        form={appointmentForm}
                        onFinish={handleAppointmentForm}
                        className="offcanvas-form"
                    >
                        <div className="fields-container">
                            {mode === 'edit' && (
                                <Row className='form-row'>
                                    <span className="input-header">
                                        Appointment ID <span className="asterisk">*</span>
                                    </span>
                                    <Form.Item name="appointment_sr_no" rules={[{ required: true }]}>
                                        <Input
                                            className="form-input"
                                            disabled
                                        />
                                    </Form.Item>
                                </Row>
                            )}
                            <Row className='form-row'>
                                <span className="input-header">
                                    Appointment name <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="description" rules={[{ required: true, message: "Appointment name is required" }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Type
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="type" rules={[{ required: true, message: "Please select the appointment type" }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        options={appointmentOptions}
                                    />
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Doctor
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="provider_id" rules={[{ required: module === 'patient', message: "Please select a doctor" }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        defaultValue={module === 'staff' ? inputData?.name : null}
                                        disabled={module === 'staff'}
                                        onChange={() => handleDoctorChange} // Track selection
                                    >
                                        {providers.length > 0 &&
                                            providers?.map((item) => (
                                                <Option key={item.provider_id} value={item.provider_id}>
                                                    {`${item.firstname} ${item.lastname}`}
                                                </Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Row>
                            {/* <Row className='form-row'>
                                <span className="input-header">Clinic branch
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="practice_id" rules={[{ required: false }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        value={selectedAddress || data?.address}
                                    >
                                        {selectedAddress && (
                                            <Option value={selectedAddress}>{selectedAddress}</Option>
                                        )}
                                        
                                    </Select>
                                </Form.Item>
                            </Row> */}
                            <Row className='form-row'>
                                <span className="input-header">Patient
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="patient_id" rules={[{ required: module === 'staff', message: "Please select a patient" }]}>
                                    <Select
                                        className="form-input"
                                        bordered={false}
                                        placeholder="Select"
                                        suffixIcon={<img src={moreIcon} alt="More Icon" />}
                                        disabled={module === 'patient'}
                                        defaultValue={module === 'patient' && patients ? patients[0]?.name : null}
                                    >
                                        {patients.length > 0 && patients?.map((patient) => (
                                            <Option key={patient.patientId} value={patient.patientId}>
                                                {`${patient.firstname} ${patient.lastname}`}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Date
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="date" rules={[{ required: true, message: "Please select a date" }]}>
                                    <DatePicker
                                        // onChange={(date) => setAppointmentDate(date)}
                                        className="form-input"
                                        suffixIcon={<img src={calendarIcon} alt="Calendar" className="calendar-icon" />}
                                    />
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Duration
                                    <span className="asterisk">*</span>
                                </span>
                                <div className='timings'>
                                    <Form.Item name="time" rules={[{ required: true, message: "Please choose the time" }]}>
                                        <TimePicker
                                            suffixIcon={<img src={moreIcon} alt="More Icon"/>}
                                            use12Hours
                                            format="h:mm A"
                                            onChange={handleStartTimeChange}
                                        />
                                    </Form.Item>
                                    <span>to</span>
                                    <Form.Item name="length" rules={[{ required: true, message: "Duration is required" }]}>
                                        <Input
                                            className="duration"
                                            suffix='Mins'
                                        />
                                    </Form.Item>
                                </div>
                            </Row>
                        </div>
                        <div className="buttons-container">
                            <Button className="cancel-button" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'add'}>
                                Save
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'edit'}>
                                Add
                            </Button>
                        </div>
                    </Form>
                </Spin>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default AppointmentOffcanvas;