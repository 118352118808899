import { Button, Form, Input, message, Row, Spin, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import API_BASE_URL from "../../../../../../env";
import './NotesOffcanvas.css';

const NotesOffcanvas = ({ show, setShow, mode, data, getNotes }) => {
    const Token = localStorage.getItem("Token");
    const [providerId, setProviderId] = useState();
    const [notesForm] = Form.useForm();
    const [spinner, setSpinner] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileId, setFileId] = useState(null);

    useEffect(() => {
        if (mode === 'edit') {
            notesForm.setFieldsValue({
                title: data.title || '',
                noteDetails: data.noteDetails || '',
            });
            setProviderId(data?.providerId);

            if (data.noteUrls && data.noteUrls.length > 0) {
                const formattedFiles = data.noteUrls.map((url, index) => ({
                    uid: data.attachmentIds[index], // unique identifier
                    name: url.split('/').pop(), // extract filename from URL
                    status: 'done', // status 'done' to mark it as uploaded
                    url, // URL to the file
                }));
                setFileList(formattedFiles);
            }
        } else {
            setProviderId(data);
            setFileList([]);
        }
    }, [data])

    const handleClose = () => {
        setShow(false);
        notesForm.resetFields();
        setFileList([]);
    };

    const handleNoteForm = () => {
        setSpinner(true);

        const config = {
            headers: { Authorization: `Bearer ${Token}` },
            params: {
                title: notesForm.getFieldValue('title'),
                noteDetails: notesForm.getFieldValue('noteDetails'),
                ...(mode === 'edit' && { noteId: data?.noteId, id: fileId }),
            },
        };

        const payload = new FormData();
        fileList.forEach((item) => {
            if (item.originFileObj) payload.append('attachment', item.originFileObj);
        });


        if (mode === 'add') {
            axios
                .post(`${API_BASE_URL}/api/v1/clinicNotes/clinic-notes-attachments/${providerId}`, payload, config)
                .then((res) => {
                    message.success('Note added successfully');
                    setSpinner(false);
                    handleClose();
                    getNotes();
                })
                .catch((err) => {
                    message.error('Error adding note');
                    setSpinner(false);
                    handleClose();
                })
        } else {
            // const config = {
            //     headers: {
            //         Authorization: `Bearer ${Token}`
            //     }
            // };

            // const payload = {
            //     title: notesForm.getFieldValue('title'),
            //     noteDetails: notesForm.getFieldValue('noteDetails')
            // };

            axios
                .put(`${API_BASE_URL}/api/v1/clinicNotes/update-notes/${providerId}`, payload, config)
                .then((res) => {
                    message.success('Note updated successfully');
                    setSpinner(false);
                    handleClose();
                    getNotes();
                })
                .catch((err) => {
                    message.error('Error updating note');
                    setSpinner(false);
                    handleClose();
                })
        }
    }

    const isUploadDisabled = () => {
        if (mode === 'edit') {
            return fileList?.length === data.noteUrls?.length
        } else {
            return false;
        }
    }

    return (
        <Offcanvas show={show} onHide={() => setShow(false)} placement="end" className='notes-off-canvas'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{mode === 'add' ? 'Add' : 'Edit'} Note</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Spin spinning={spinner} tip={mode === 'add' ? 'Adding...' : 'Updating...'}>
                    <Form
                        form={notesForm}
                        onFinish={handleNoteForm}
                        className="offcanvas-form"
                    >
                        <div className="fields-container">
                            <Row className='form-row'>
                                <span className="input-header">
                                    Title <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="title" rules={[{ required: true }]}>
                                    <Input
                                        className="form-input"
                                    />
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Note details
                                    <span className="asterisk">*</span>
                                </span>
                                <Form.Item name="noteDetails" rules={[{ required: true }]}>
                                    <TextArea
                                        className="form-text-area"
                                    />
                                </Form.Item>
                            </Row>
                            <Row className='form-row'>
                                <span className="input-header">Attachment
                                </span>
                                <Input
                                    className="form-upload-input"
                                    suffix={
                                        <Form.Item name="attachment">
                                            <Upload
                                                listType="picture"
                                                beforeUpload={() => false}
                                                fileList={fileList}
                                                onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                                onRemove={(file) => {
                                                    setFileId(file.uid);
                                                    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
                                                }}
                                            >
                                                <Button
                                                    className="browse-button"
                                                    disabled={isUploadDisabled()}
                                                >
                                                    Browse
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    }
                                />
                            </Row>
                        </div>
                        <div className="buttons-container">
                            <Button className="cancel-button" onClick={() => setShow(false)}>
                                Cancel
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'add'}>
                                Save
                            </Button>
                            <Button className="add-button" htmlType="submit" hidden={mode === 'edit'}>
                                Add
                            </Button>
                        </div>
                    </Form>
                </Spin>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default NotesOffcanvas;