import { AgGridReact } from "ag-grid-react";
import { Button, message, Modal, Spin } from "antd";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import addIcon from "../../../../assets/add.png";
import closeIcon from "../../../../assets/close.png";
import deleteIcon from "../../../../assets/delete.png";
import editIcon from "../../../../assets/edit.png";
import infoIcon from "../../../../assets/info.png";
import API_BASE_URL from "../../../../env";
import { routes_patient_details } from "../../../../utils/RoutingConts";
import CellWithOverflowTooltip from "../../../CellWithOverflowTooltip/CellWithOverflowTooltip";
import AppointmentOffcanvas from "../../../staff/staff-details/tabs/appointments/appointmentOffcanvas/AppointmentOffcanvas";

const PatientAppointments = () => {
    const Token = localStorage.getItem("Token");
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const { patientId } = useParams();
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [staffDetails, setStaffDetails] = useState([]);
    const [selectedAppointment, setSelectedAppointment] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [appointmentId, setAppointmentId] = useState();
    const [patient, setPatient] = useState([]);

    const handleAddOpen = () => {
        setAddShow(true);
    };

    const handleEditOpen = (id) => {
        const appointment = appointments.find(appointment => appointment.id === id);
        setSelectedAppointment(appointment);
        setEditShow(true);
    };

    useEffect(() => {
        setLoading(true);
        getStaffData();
        getAppointments();
        getPatientById();
    }, [patientId]);

    const config = {
        headers: {
            Authorization: `Bearer ${Token}`
        },
        params: {
            patientId: patientId
        }
    }

    const getAppointments = () => {
        axios
            .get(`${API_BASE_URL}/api/v1/Appointments/get-appointments`, config)
            .then(response => {
                setAppointments(response.data);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the data");
                setLoading(false);
            })
    }

    const getStaffData = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        };

        axios
            .get(`${API_BASE_URL}/api/v1/providers/get-all-providers`, config)
            .then((res) => {
                const providers = res.data.data.map((provider) => ({
                    id: provider.provider_id, // Extract provider_id
                    name: `${provider.firstname} ${provider.lastname}`, // Combine first and last name
                    specialty: provider.specialty_code || "N/A" // Extract specialty_code or fallback to "N/A"
                }));
                setStaffDetails(providers); // Update the state with the array of providers
                setLoading(false);
            })
            .catch((err) => {
                console.error("Failed to fetch providers:", err);
            });
    };

    const getPatientById = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                patientId: patientId
            }
        }

        axios
            .get(`${API_BASE_URL}/api/v1/patients/fetchPatientsByPatientId`, config)
            .then(res => {
                const patient = [];
                patient.push(res.data);
                setPatient(patient);
                setLoading(false);
            })
            .catch(err => {
                message.error(err.response?.data.error ? err.response?.data.error : "Failed to fetch the patients data");
            })
    }

    const handleAppointmentDelete = (id) => {
        setOpenDeleteModal(true);
        setAppointmentId(id);
    }

    const deleteAppointment = () => {
        const config = {
            headers: {
                Authorization: `Bearer ${Token}`
            },
            params: {
                appointmentSrNo: appointmentId
            }
        }

        axios
            .delete(`${API_BASE_URL}/api/v1/Appointments/delete_appointments_by_appointmentId`, config)
            .then((res) => {
                setOpenDeleteModal(false);
                message.success(res.data);
                getAppointments();
            })
            .catch((err) => {
                setOpenDeleteModal(false);
                message.error(err.res);
            })
    }

    const appointmentTime = (time, length) => {
        // Helper function to convert 24-hour time to 12-hour format
        const to12HourFormat = (hours, minutes) => {
            const period = hours >= 12 ? 'PM' : 'AM';
            const hours12 = hours % 12 || 12; // Convert 0 or 12 to 12
            return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
        };

        // Helper function to parse time (handles both 24-hour and 12-hour formats)
        const parseTime = (time) => {
            if (/AM|PM/i.test(time)) {
                const match = time.match(/^(\d+):(\d+)\s*(AM|PM)$/i);
                if (!match) throw new Error("Invalid time format");
                let [_, hours, minutes, period] = match; // Destructure match groups
                hours = Number(hours);
                minutes = Number(minutes);
                if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12; // Convert PM to 24-hour format
                if (period.toUpperCase() === 'AM' && hours === 12) hours = 0; // Handle midnight
                return [hours, minutes];
            } else if (/^\d{1,2}:\d{2}$/.test(time)) {
                return time.split(':').map(Number); // Already in 24-hour format
            } else {
                throw new Error("Invalid time format");
            }
        };

        try {
            // Parse the start time
            const [startHours, startMinutes] = parseTime(time);

            // Calculate the end time
            const totalMinutes = startHours * 60 + startMinutes + Number(length);
            const endHours = Math.floor(totalMinutes / 60) % 24; // Handle overflow past midnight
            const endMinutes = totalMinutes % 60;

            // Convert start and end times to 12-hour format
            const startFormatted = to12HourFormat(startHours, startMinutes);
            const endFormatted = to12HourFormat(endHours, endMinutes);

            return `${startFormatted} - ${endFormatted}`;
        } catch (error) {
            console.error("Error parsing time:", error.message);
            return "Invalid time";
        }
    };

    const appointmentDate = (day) => {
        const date = new Date(day);

        const options = { day: '2-digit', month: 'short' };
        return date.toLocaleDateString('en-UK', options);
    }

    const columnDefs = [
        {
            field: "appointment_sr_no",
            headerName: "APPOINTMENT ID",
            cellRenderer: (params) => {
                const { appointment_sr_no } = params.data;
                return (
                    <div className="row-data-element">
                        <Link to={`${routes_patient_details}/${patientId}/appointment-details/${appointment_sr_no}`}>
                            <span className="app-id">{appointment_sr_no}</span>
                        </Link>
                    </div>
                );
            },
            flex: 1,
            minWidth: 140
        },
        {
            field: "description",
            headerName: "APPOINTMENT NAME",
            cellRenderer: (params) => {
                const { description } = params.data;
                return (
                    <div className="row-data-element">
                        <div className="full-name">
                            <CellWithOverflowTooltip value={description} />
                        </div>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "date",
            headerName: "DATE & TIME",
            cellRenderer: (params) => {
                const { date, time, length } = params.data;

                return (
                    <div className="row-data-element">
                        <div className="date-time">
                            {appointmentDate(date)}
                            <span>{appointmentTime(time, length)}</span>
                        </div>

                    </div>
                );
            },
            minWidth: 230
        },
        {
            field: "type",
            headerName: "TYPE",
            cellRenderer: (params) => {
                const { type } = params.data;
                return (
                    <div className="row-data-element">
                        <div>{type}</div>
                    </div>
                );
            },
            minWidth: 230
        },
        {
            field: "contact",
            headerName: "DOCTOR",
            cellRenderer: (params) => {
                const { provider_id } = params.data;
                const staff = staffDetails.filter((staff) => {
                    if (staff.id === provider_id) {
                        return staff
                    }
                })

                return (
                    <div className="row-data-element">
                        <span>{staff[0]?.name}</span>
                        <span>{staff[0]?.specialty}</span>
                    </div>
                );
            },
            minWidth: 220
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            cellRenderer: (params) => {
                const { id, appointment_sr_no } = params.data;
                return (
                    <div className='actions'>
                        <img src={editIcon} alt="Edit" onClick={() => handleEditOpen(id)} />
                        <img src={deleteIcon} alt="Delete" onClick={() => handleAppointmentDelete(appointment_sr_no)} />
                    </div>
                );
            },
            pinned: 'right',  // This fixes the column to the right side
            suppressMovable: true,  // Prevent this column from being draggable
            lockPosition: true,
            sortable: false, // Optional: Disable sorting if not needed
            filter: false,
            resizable: false,
            minWidth: 88,
        }
    ];

    const defaultColDef = useMemo(() => {
        return {
            // flex: 2,
            resizable: true, // Enable column resizing
            autoHeight: true,
            wrapText: true
        };
    }, []);

    const rowHeight = 80;

    const rowSelection = useMemo(() => {
        return {
            mode: "multiRow",
        };
    }, []);

    const gridOptions = {
        suppressHorizontalScroll: false, // Enable horizontal scrolling
        overlayNoRowsTemplate: '<span class="custom-no-rows">No appointments to display.</span>',
    }

    const onFirstDataRendered = (params) => {
        params.api.paginationGoToPage(0); // Optional, but ensures the grid shows the first page
    };

    return (
        <div className="appointments-container">
            <div className="appointments-header">
                <span className="appointment-text">Appointments</span>
                <div className="add-appointment" onClick={() => { handleAddOpen() }}>
                    <img src={addIcon} alt="Add" className="add-icon" />
                    <span className="add-text">Appointment</span>
                </div>
            </div>
            <div className="appointment-list">
                {loading && (
                    <Spin className="spinner" />
                )}

                {!loading && (
                    <div style={gridStyle} className="ag-theme-quartz">
                        <AgGridReact
                            ref={gridRef}
                            rowData={appointments}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            onFirstDataRendered={onFirstDataRendered}
                            tooltipShowDelay={0}
                            rowSelection={rowSelection}
                            rowHeight={rowHeight}
                            gridOptions={gridOptions}
                        />
                    </div>
                )}

            </div>

            <AppointmentOffcanvas
                show={addShow}
                setShow={setAddShow}
                mode={"add"}
                module={"patient"}
                inputData={patient}
                getAppointments={getAppointments}
            />
            <AppointmentOffcanvas
                show={editShow}
                setShow={setEditShow}
                mode={"edit"}
                module={"patient"}
                inputData={patient}
                selectedAppointment={selectedAppointment}
                getAppointments={getAppointments}
            />

            <Modal
                open={openDeleteModal}
                centered
                closable={false}
                footer={false}
            >
                <div className="modal-body">
                    <div className="close" onClick={() => setOpenDeleteModal(false)}><img src={closeIcon} alt="Close" /></div>
                    <div className="header">
                        <img src={infoIcon} alt="Info" className="info-icon" />
                        Delete Appointment
                    </div>
                    <div className="body">
                        <span className="description">You are trying to delete a appointment,
                            this will remove all the appointment data.
                            Are you sure you want to continue?
                        </span>

                    </div>
                    <div className="buttons-container">
                        <Button className="cancel" onClick={() => setOpenDeleteModal(false)}>No</Button>
                        <Button className="yes" onClick={deleteAppointment}>Yes</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PatientAppointments;